<template>
  <v-card flat class="mx-auto my-2">
    <v-card-tex v-if="product">
      <v-row class="align-center">
        <v-col cols="2" class="text-right">
          <ProductDialog
            v-on:itemSelected="setProduct"
            class="px-2 d-inline-block"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-badge bordered color="error" icon="mdi-plus" overlap>
                <v-btn icon color="green accent-2" v-bind="attrs" v-on="on">
                  <v-icon large>mdi-tshirt-v</v-icon>
                </v-btn>
              </v-badge>
            </template>
          </ProductDialog>

          <span class="overline ml-2">Product</span>
        </v-col>
        <v-col cols="10">
          <v-form ref="productform">
            <v-container row class="align-center">
              <v-flex xs6 sm6 md4 lg4>
                <v-text-field
                  v-model="productName"
                  label="Product Name"
                  required
                  hint="Product short description"
                ></v-text-field
              ></v-flex>
              <v-flex md4 lg3>
                <v-chip
                  class="ma-2"
                  color="light-green accent-4 white--text"
                  label
                >
                  <v-icon left> mdi-tshirt-crew </v-icon>
                  {{ supplierName }}
                </v-chip>
              </v-flex>
              <v-flex xs6 sm6 md4 lg3>
                <v-file-input
                  v-model="productImageUpload"
                  accept="image/png, image/jpeg, image/bmp"
                  placeholder="Pick an image"
                  prepend-icon="mdi-camera"
                  label="Product Image"
                ></v-file-input>
              </v-flex>

              <v-flex xs6 sm6 md2 lg2>
                <v-card max-width="150" class="mx-2 pa-2">
                  <v-img
                    class="rounded"
                    :lazy-src="resolveImageLazySrc"
                    max-width="150"
                    :src="resolveImageSrc"
                  ></v-img>
                </v-card>
              </v-flex>
            </v-container>
            <v-container row>
              <v-flex xs6 sm6 md4 lg12>
                <v-textarea
                  v-model="productDescription"
                  rows="4"
                  label="Product Description"
                  hint="Full description of the product"
                ></v-textarea>
              </v-flex>
            </v-container>
            <v-container row>
              <v-flex xs6 sm6 md2 lg2>
                <v-text-field
                  v-model="productCost"
                  required
                  prefix="$"
                  label="Product Cost"
                  @keyup="calculateProductMarkedUpPrice"
                >
                </v-text-field>
              </v-flex>
              <v-flex xs6 sm6 md2 lg2>
                <v-text-field
                  v-model="productMarkUpRate"
                  required
                  label="Mark up Rate"
                  class="mr-2"
                  prepend-icon="mdi-plus mdi-rotate-45"
                  @keyup="calculateProductMarkedUpPrice"
                >
                </v-text-field>
              </v-flex>
              <v-flex xs6 sm6 md2 lg2>
                <v-text-field
                  v-model="productMarkUpPercentage"
                  required
                  label="Mark up Percentage"
                  suffix="%"
                  class="mr-2"
                  readonly
                >
                </v-text-field>
              </v-flex>
              <v-flex xs6 sm6 md2 lg2>
                <v-text-field
                  v-model="productMarkedUpPrice"
                  required
                  label="Marked up Price"
                  prefix="$"
                  prepend-icon="mdi-equal"
                >
                </v-text-field>
              </v-flex>
            </v-container>
          </v-form>
        </v-col>
      </v-row>
      <v-row class="align-center">
        <v-col cols="2" class="text-right"
          ><v-icon color="red accent-2">mdi-basket-fill</v-icon>&nbsp;<span
            class="overline ml-2"
            >Quantity</span
          >
        </v-col>
        <v-col cols="10">
          <v-container row class="align-center">
            <v-flex xs6 sm6 md2 lg2>
              <v-text-field v-model="quantitySlider" class="text-h4" required>
                <template v-slot:prepend
                  ><v-btn icon v-bind="attrs" v-on="on" @click="decrementQty">
                    <v-icon>mdi-minus</v-icon>
                  </v-btn></template
                >
                <template v-slot:append>
                  <v-btn icon v-bind="attrs" v-on="on" @click="incrementQty">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-flex>
          </v-container>
        </v-col>
      </v-row>

      <v-row class="align-center">
        <v-col cols="2" class="text-right"
          ><v-icon color="red accent-2">mdi-table</v-icon>&nbsp;<span
            class="overline ml-2"
            >Price Matrix</span
          >
        </v-col>
        <v-col cols="10">
          <v-container row class="align-center">
            <v-col cols="12">
              <template>
                <v-data-table
                  v-model="selectedProductMatrix"
                  :headers="headers"
                  :items="priceMatrix"
                  :single-select="true"
                  item-key="uuid"
                  show-select
                  class="elevation-1"
                >
                </v-data-table>
              </template>
            </v-col>
          </v-container>
        </v-col>
      </v-row>
    </v-card-tex>
    <v-card-text class="my-10" v-if="!product">
      <v-row class="align-center align-justify">
        <v-col cols="12" class="text-center">
          <ProductDialog v-on:itemSelected="setProduct" class="px-2">
            <template v-slot:activator="{ on, attrs }">
              <v-badge bordered color="error" icon="mdi-plus" overlap>
                <v-btn icon color="green accent-2" v-bind="attrs" v-on="on">
                  <v-icon large>mdi-tshirt-v</v-icon>
                </v-btn>
              </v-badge>
            </template>
          </ProductDialog>
          <span class="overline">Add product</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import ProductDialog from "../dialogs/ProductDialog.vue";
import MpsMath from "../../helpers/MpsMath.js";

export default {
  emits: ["productUpdated"],
  props: [
    "calQuantity",
    "calProductCost",
    "calProductMarkupRate",
    "calProductMarkupPercentage",
    "calProductMarkedupPrice",
  ],
  components: {
    ProductDialog,
  },
  data() {
    return {
      formValidate: false,
      product: null,
      quantitySlider: this.calQuantity,
      productCost: this.calProductCost,
      productMarkUpRate: this.calProductMarkupRate,
      productMarkUpPercentage: this.calProductMarkupPercentage,
      productMarkedUpPrice: this.calProductMarkedupPrice,
      quantitySliderMin: 1,
      productName: "",
      productDescription: "",
      productImageUpload: null,
      productImageSrc: null,
      productImageLazySrc: null,
      //------------------product price matrix data-------------------
      selectedProductMatrix: [],
      headers: [
        {
          text: "Price Level",
          align: "start",
          sortable: false,
          value: "priceLevel",
        },
        { text: "Quantity From", value: "qtyFrom" },
        { text: "Quantity To", value: "qtyTo" },
        { text: "Cost Price", value: "costPrice" },
        { text: "Selling Price", value: "sellingPrice" },
      ],
      priceMatrix: [],
    };
  },
  watch: {
    formValidate(newFormValidate) {
      console.log(newFormValidate);
    },
    productMarkUpRate(newProductMarkUpRate) {
      this.productMarkUpPercentage =
        +MpsMath.percentageOfRate(newProductMarkUpRate);
    },
    productMarkedUpPrice(newProductMarkedUpPrice) {
      //set markup rate
      this.productMarkUpRate = MpsMath.markUpRateBetweenOf(
        this.productCost,
        newProductMarkedUpPrice
      );
    },
    selectedProductMatrix(newSelectedProductMatrix) {
      if (newSelectedProductMatrix.length) {
        //set prices
        this.productCost = +this.selectedProductMatrix[0].costPrice;
        this.productMarkedUpPrice = +this.selectedProductMatrix[0].sellingPrice;

        //set quantity
        this.quantitySlider = +this.selectedProductMatrix[0].qtyFrom;

        //set markup rate
        this.productMarkUpRate = MpsMath.markUpRateBetweenOf(
          this.productCost,
          this.productMarkedUpPrice
        );
      }
    },
    product(newProduct) {
      this.productName = newProduct.name;
      this.productDescription = newProduct.description;
      this.productCost = +newProduct.costPrice;
      this.productMarkedUpPrice = +newProduct.markedUpPrice;
      this.priceMatrix = newProduct.priceMatrix;
      this.productImageSrc = newProduct.imageSrc;
      this.productImageLazySrc = newProduct.imageLazySrc;

      //setting markup rate
      if (this.productCost && this.productMarkedUpPrice) {
        this.productMarkUpRate = MpsMath.markUpRateBetweenOf(
          this.productCost,
          this.productMarkedUpPrice
        );
      }
    },
  },
  computed: {
    supplierName() {
      return this.product?.supplier ? this.product?.supplier?.name : "";
    },
    resolveImageSrc() {
      return this.productImageSrc
        ? this.productImageSrc
        : require("../../assets/img-placeholder.png");
    },

    resolveImageLazySrc() {
      return this.productImageLazySrc
        ? this.productImageLazySrc
        : require("../../assets/img-placeholder.png");
    },
  },
  methods: {
    setProduct(obj) {
      this.product = obj;
    },
    decrementQty() {
      this.quantitySlider = +this.quantitySlider - 20;

      if (this.quantitySlider < this.quantitySliderMin) {
        this.quantitySlider = this.quantitySliderMin;
      }
    },

    incrementQty() {
      this.quantitySlider = +this.quantitySlider + 20;
    },

    calculateProductMarkedUpPrice() {
      const cost = +this.productCost;
      const rate = +this.productMarkUpRate;

      this.productMarkedUpPrice = +(cost * rate).toFixed(2);
    },
    assembleProduct() {
      if (this.product) {
        this.product.name = this.productName;
        this.product.description = this.productDescription;
        this.product.quantity = this.quantitySlider;
        this.product.costPrice = this.productCost;
        this.product.markUpRate = this.productMarkUpRate;
        this.product.markUpPercentage = this.productMarkUpPercentage;
        this.product.markedUpPrice = this.productMarkedUpPrice;
        this.product.priceMatrix = this.priceMatrix;
        this.product.imageSrc = this.productImageSrc;
        this.product.imageLazySrc = this.productImageLazySrc;
      }
    },
    emitProduct() {
      this.assembleProduct();
      this.$emit("productUpdated", this.product);
      //this.formValidate = this.$refs.headerform.validate();
      //console.log(this.formValidate);
    },
  },
  mounted() {
    console.log("mounted");
  },
  updated() {
    console.log("updated");
    this.emitProduct();
  },
};
</script>