<template>
  <v-container class="my-1">
    <ItemCalculationScreenDialog
      class="px-2"
      :edited-id="editedId"
      :dialog.sync="dialog"
      @close-dialog="
        editedId = null;
        dialog = false;
      "
    />
    <v-container row class="mb-1">
      <v-toolbar dense>
        <v-btn icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="grey" v-bind="attrs" v-on="on">
              <v-icon>mdi-content-duplicate</v-icon>
            </v-btn>
          </template>
          <span>Clone Selected Item(s)</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="grey" v-bind="attrs" v-on="on">
              <v-icon>mdi-delete-sweep</v-icon>
            </v-btn>
          </template>
          <span>Delete Selected</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="grey" v-bind="attrs" v-on="on">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Add Item</span>
        </v-tooltip>
      </v-toolbar>
    </v-container>

    <v-card flat v-for="(index, item) in itemDetails" :key="index" class="mb-1">
      <v-container row :class="`pa-4 quote`">
        <v-flex xs6 sm6 md3 lg2>
          <v-row align="center">
            <v-col cols="2">
              <v-checkbox
                v-model="ex4"
                color="red"
                value="red"
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col cols="10">
              <div class="caption grey--text">Item</div>
              <div>
                <v-img
                  class="rounded"
                  lazy-src="https://picsum.photos/id/11/10/6"
                  max-width="180"
                  src="https://picsum.photos/id/11/500/300"
                ></v-img>
              </div>
              <div class="font-weight-bold subtitle-2">
                P3200_BIZNZ {{ item?.name }}
              </div>
            </v-col>
          </v-row>
        </v-flex>
        <v-flex xs2 sm4 md4 lg4 px-2>
          <div class="caption grey--text">Description</div>
          <div class="caption">Mens Elite Polo - NVY/WHT - M</div>
          <p class="text-justify caption">
            Fabrics: BIZ COOL 100% Breathable Polyester. Textured mini...
          </p>
          <p class="text-justify caption">
            <span class="d-block"
              >1.Embroidery to Garment up to 5000 stitches...</span
            >
            <span class="d-block"
              >2.Screen Print to Garment: 1 Colour, 1 Position...</span
            >
          </p>
          <p class="text-justify caption"></p>
        </v-flex>
        <v-flex xs2 sm4 md1 lg1 class="text-lg-center">
          <div class="caption grey--text">Account</div>
          <div class="font-weight-bold subtitle-2">10010 [ REVENUE ]</div>
        </v-flex>
        <v-flex xs2 sm4 md2 lg1 class="text-lg-center text-md-center">
          <div class="caption grey--text">Quantity</div>
          <div class="font-weight-bold">100</div>
        </v-flex>
        <v-flex xs2 sm4 md2 lg1 class="px-6 text-lg-right text-md-right">
          <div class="caption grey--text" align="center">Unit Price</div>
          <div class="font-weight-bold">$250.00</div>
          <span class="subtitle-2 grey--text d-block">Cost $150 </span>
        </v-flex>
        <v-flex xs2 sm4 md1 lg1 class="text-lg-center">
          <div class="caption grey--text" align="center">Tax</div>
          <div class="font-weight-bold">GST [ 1.1 ]</div>
        </v-flex>
        <v-flex xs2 sm4 md3 lg1 class="text-lg-right text-md-right">
          <div class="caption grey--text" align="center">Amount</div>
          <div class="font-weight-bold">$25000.00</div>
          <span class="subtitle-2 grey--text d-block">Cost $15000 </span>
        </v-flex>
        <v-flex
          xs2
          sm4
          md2
          lg1
          class="text-lg-center text-md-center quote-row-option"
        >
          <div class="caption grey--text">Options</div>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="red lighten-3"
                v-bind="attrs"
                v-on="on"
                @click="addItem"
              >
                <v-icon>mdi-content-duplicate</v-icon>
              </v-btn>
            </template>
            <span>Clone Item</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon color="red lighten-3" v-bind="attrs" v-on="on">
                <v-icon>mdi-delete-forever</v-icon>
              </v-btn>
            </template>
            <span>Delete Item</span>
          </v-tooltip>
        </v-flex>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import ItemCalculationScreenDialog from "./dialogs/ItemCalculationScreenDialog.vue";

export default {
  props: ["itemDetails"],
  components: {
    ItemCalculationScreenDialog,
  },
  data() {
    return {
      editedId: null,
      dialog: true,
    };
  },
  methods: {
    addItem() {
      console.log("add item");
      this.dialog = true;
    },
    editItem(item) {
      this.editedId = item.id;
      this.dialog = true;
    },
  },
};
</script>