<template>
  <v-container class="my-2">
    <QuoteAdvancedFilterDialog      
      :dialog.sync="dialogAdvancedFilter"
      @close-quote-filter-dialog="dialogAdvancedFilter = false;"
     />
    <v-layout row class="mb-2" align-center justify-center>
      <span class="caption mr-2 grey--text">Sort By</span>
      <v-btn
        :class="{ 'pink--text': sortBy.active }"
        small
        depressed
        @click="toggleSortBy(sortBy.id)"
        v-for="sortBy in sortables"
        :key="sortBy.id"
      >
        <v-icon left small>{{ sortBy.icon }}</v-icon>
        <span class="caption text-lowercase">{{ sortBy.label }}</span>
        <v-icon v-if="sortBy.active" right x-small color="pink darken-1"
          >mdi-call-received
          {{ sortBy.sort > 0 ? "mdi-rotate-315" : "mdi-rotate-135" }}</v-icon
        >
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn depressed @click="showAdvancedFilter">
        <v-icon>mdi-tune</v-icon>
      </v-btn>

      <v-text-field
        v-model="filterSearchText"
        label="Search by number, name, customer, salesperson etc."
        single-line
        @keydown.enter="searchForText"
        clearable
      ></v-text-field>
      <v-btn depressed @click="searchForText">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </v-layout>
   
  </v-container>
</template>

<script>
import QuoteAdvancedFilterDialog from './dialogs/QuoteAdvancedFilterDialog.vue'
export default {
  components: {
    QuoteAdvancedFilterDialog
  },
  props: {    
    sortables: {
      type: Array,
      required: true,
    },
    sortParameter: {
      type: String,
    } ,
    searchText: {
      type: String
    }   
  },
  emits:['on-change-per-page', 'on-change-page', 'on-search', 'on-sort-toggle'],
  data() {
    return {
      /* extract pagination object **/
      currentPage: this.pagination.currentPage,
      perPage: this.pagination.perPage,      
      paginationTotalCount: this.pagination.paginationTotalCount,
      perPageSelectors: this.pagination.perPageSelectors,
      sortParam: this.sortParameter,
      filterSearchText: this.searchText,
    };
  },
 
  methods: {
    //advanced filter dialog
    showAdvancedFilter() {
      this.dialogAdvancedFilter = true;
    },
    //on click @ search text button
    searchForText() {
      this.$emit('on-search', this.filterSearchText);
    },
    //on click @ sort by btn
    toggleSortBy(id) {
      //set all active to false
      this.sortables.forEach((element) => (element.active = false));
      const sortBy = this.sortables.find((element) => element.id === id);
      //prepend the sort sign to the attribute

      sortBy.sort = sortBy.sort > 0 ? -1 : 1;
      sortBy.active = true;
      this.sortParam = `${sortBy.sort < 0 ? "-" : ""}${sortBy.dataAttr}`;

      this.$emit('on-sort-toggle', this.sortParam);
     
    },
    
  },
};
</script>
