<template>
  <v-app>    
    <v-main class="grey lighten-4">
      <v-container>
        <v-layout row class="mb-2" align-center justify-center>
            <v-card flat id="card-error" class="pa-10">
              <v-card-title class="justify-center display-1"><v-icon color="pink lighten-2" x-large>mdi-security</v-icon></v-card-title>
              <v-card-title class="justify-center display-1 green--text">Oops! Something went wrong.</v-card-title>
            </v-card>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
  
</template>

<script>
export default {
  name: "ErrorLayout",
  components: {},
};
</script>

<style scoped>
#card-error {
  width: 30vw;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -75%);
}
</style>