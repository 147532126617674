<template>
  <div class="view-quote">
    <h1 class="subheading grey--text">View Quotation</h1>
    <v-stepper flat>
      <v-stepper-header>
        <v-stepper-step
          color="light-green accent-4"
          step="1"
          :rules="stepperOneRules"
        >
          <span class="text-uppercase">Fill header info</span>
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="2">
          <span class="text-uppercase">Add item details</span>
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3">
          <span class="text-uppercase">Create order confirmation</span>
        </v-stepper-step>
      </v-stepper-header>
    </v-stepper>
    <v-card flat>
      <v-toolbar color="light-green accent-4" dark flat>
        <template>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="grey"></v-tabs-slider>
            <v-tab key="tab1"> header </v-tab>
            <v-tab key="tab2"> item details </v-tab>
            <v-tab key="tab3"> crm </v-tab>
          </v-tabs>
          <v-spacer></v-spacer>
          <v-btn-toggle v-model="toggle_multiple" multiple>
            <v-btn color="light-green accent-4"> OFFSHORE PRODUCTION </v-btn>

            <v-btn color="light-green accent-4"> URGENT </v-btn>

            <v-btn color="light-green accent-4"> OTHER OPTION </v-btn>
          </v-btn-toggle>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon color="white--text" v-bind="attrs" v-on="on">
                <v-icon>mdi-source-branch mdi-rotate-90</v-icon>
              </v-btn>
            </template>
            <span>Create Order Confirmation</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon color="white--text" v-bind="attrs" v-on="on">
                <v-icon>mdi-content-duplicate</v-icon>
              </v-btn>
            </template>
            <span>Clone Quote</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon color="white--text" v-bind="attrs" v-on="on">
                <v-icon>mdi-email-outline</v-icon>
              </v-btn>
            </template>
            <span>Send Quote</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon color="white--text" v-bind="attrs" v-on="on">
                <v-icon>mdi-printer</v-icon>
              </v-btn>
            </template>
            <span>Print PDF</span>
          </v-tooltip>
        </template>
      </v-toolbar>

      <v-tabs-items v-model="tab">
        <v-tab-item key="tab1">
          <QuoteHeaderForm v-if="!quoteHeaderLoading" :quotationHeader="quotationHeader" @quote-header-updated="updateQuoteHeader"/>
        </v-tab-item>
        <v-tab-item key="tab2">
          <QuoteItemDetail :itemDetails="itemDetails" />
        </v-tab-item>
        <v-tab-item key="tab3">
          <v-card flat>
            <v-card-text> CRM records will goes hrer </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>
<script>
import QuoteHeaderForm from '@/components/QuoteHeaderForm.vue'
import QuoteItemDetail from "../components/QuoteItemDetail.vue";
export default {
  props: {
    uid: Number
  },
  components: {    
    QuoteHeaderForm,
    QuoteItemDetail,
  },

  data() {
    return {
      toggle_multiple: [0, 1, 2],
      tab: null,
      
      //-----------rule holder for stepper one  -------------------//
      stepperOneRule: [],

      //-------------QUOTE HEADER DATA-------------------//
      quoteHeaderLoading: false,
      quotationHeader:null, // this will be quotation object from the api without other details
      quoteSystemStatuses: [],
      quotefollowupStatuses: [],
      
      //----------------PRODUCT ITEM DATA ---------------------//
      itemDetails: [
        {
          name: "test",
        },
        {
          name: "test",
        },
      ],
      
    };
  },
  computed: {
    stepperOneRules() {
      return [() => false];
    }
  },
  methods: { 
    async fetchQuoteSystemStatuses() { 
      /** this will make query like ?filter[id][in][]=2&filter[id][in][]=5 */    
      const params = {"filter[id][in]":[1,3,4,33,37]};
      
     
      await this.$store.dispatch("fetchSystemStatuses", params);
      return this.$store.getters.systemStatuses;   
    },   
    async fetchQuoteFollowupStatuses() { 
      /** this will make query like ?filter[id][in][]=2&filter[id][in][]=5 */    
      const params = {"filter[type][eq]": "Quotation"};
      
     
      await this.$store.dispatch("fetchFollowupStatuses", params);
      return this.$store.getters.followupStatuses;   
    },   
    async fetchQuoteHeader() {     
      const params = {
        expand:"addresses"       
      };
      await this.$store.dispatch("fetchMonoQuote", {uid:this.uid, params:params});
      return this.$store.getters.monoQuote;       
    },
    async updateQuoteHeader(quoteHeader) {
      console.log('updateQuoteHeader',quoteHeader);
    }
  },
  created() {},
  async mounted() {
     this.quoteHeaderLoading = true;
    /** loading the quote system statuses */
    this.quoteSystemStatuses = await this.fetchQuoteSystemStatuses();
    /** loading the quote followup statuses */
    this.quotefollowupStatuses = await this.fetchQuoteFollowupStatuses();
    /** loading the quote header data */
    this.quotationHeader = await this.fetchQuoteHeader();
    /** Inser quote status array by modifying its key and value pair {state: "DRAFT", abbr: 1} which is used as in quote header */
    this.quotationHeader.statuses = this.quoteSystemStatuses?.map((item) => {return {state: item.name, abbr: item.id}});
     /** Inser quote follow-up status array by modifying its key and value pair {state: "Quote Followup Required", abbr: 1} which is used as in quote header */
    this.quotationHeader.followupStatuses = this.quotefollowupStatuses?.map((item) => {return {state: item.name, abbr: item.id}});
      this.quoteHeaderLoading = false;
    console.log(this.quotefollowupStatuses);
  },
};
</script>