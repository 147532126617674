import Vue from "vue";
import Vuex from "vuex";
import globalVar from "@/config/main";
import { httpClient } from "@/services/http.service";

Vue.use(Vuex);

const state = {
  //pagination related states
  perPage: globalVar.API_PER_PAGE, // GLOBAL - number of data rows per page
  //end pagination related states
  authUser: JSON.parse(localStorage.getItem(globalVar.USER_STORAGE_KEY)),
  systemStatuses: [] /** System systemStatuses */,
  followupStatuses: [] /** System systemStatuses */,
  quotes: [],
  monoQuote: null, //this will be the quote viewd by the user currently
};

const mutations = {
  setPerPage(state, payload) {
    state.perPage = payload;
  },
  setAuthUser(state, payload) {
    state.authUser = payload;
    localStorage.setItem(globalVar.USER_STORAGE_KEY, JSON.stringify(payload));
  },
  unsetAuthUser(state) {
    state.authUser = null;
    localStorage.removeItem(globalVar.USER_STORAGE_KEY);
    return true;
  },
  /** System systemStatuses */
  setSystemStatuses(state, payload) {
    state.systemStatuses = payload;
  },
   /** set follow satuses */
  setFollowupStatuses(state, payload){
    state.followupStatuses = payload;
  },
  setQuotes(state, payload) {
    state.quotes = payload;
  },
  setMonoQuote(state, payload) {
    state.monoQuote = payload;
  },
};

const actions = {
  setPerPage(context, payload) {
    context.commit("setPerPage", payload);
  },
  setAuthUser(context, payload) {
    context.commit("setAuthUser", payload);
  },
  /**
   * API CRUD operations
   */

  async fetchSystemStatuses(contex, params) {
    console.log("trying to fetch status data");
    const { status, data } = await httpClient.get("status", {params: params});
    if (status === 200) {
      contex.commit("setSystemStatuses", data);
    }
  },
  async fetchFollowupStatuses(contex, params) {
    console.log("trying to fetch followup status data");
    const { status, data } = await httpClient.get("followup-status", {params: params});
    if (status === 200) {
      contex.commit("setFollowupStatuses", data);
    }
  },
  async fetchQuotes(contex, params) {
    console.log("trying to fetch quote data");
    const { status, data } = await httpClient.get("quotation", {
      params: params,
    });
    if (status === 200) {
      contex.commit("setQuotes", data);
    }
  },
  async fetchMonoQuote(contex, payload) {
    console.log(`trying to fetch a single quote ${payload.uid}`);
    const { status, data } = await httpClient.get(`quotation/${payload.uid}`, {
      params: payload.params,
    });
    if (status === 200) {
      contex.commit("setMonoQuote", data);
    }
  },
};

const getters = {
  perPage: (state) => state.perPage,
  systemStatuses: (state) => state.systemStatuses,
  followupStatuses: (state) => state.followupStatuses,
  quotes: (state) => state.quotes,
  monoQuote: (state) => state.monoQuote, // currenlty working quote
  authUser: (state) => state.authUser,
  isAuthenticated: (state) => (state.authUser ? true : false),
};

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
});
