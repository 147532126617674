<template>
  <v-app>    
    <v-main class="grey lighten-4">
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
  
</template>

<script>
export default {
  name: "AuthLayout",
  components: {},
};
</script>