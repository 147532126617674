export default class MpsMath {
    /**
     * @description Getting markup rate based on cost and marked up value
     * formula => rate = price / cost
     * 
     * @param {Number} cost 
     * @param {Number} price 
     * 
     * @returns Number
     */
    static markUpRateBetweenOf(cost,price) {
        if(+cost && +price) {
            return +(price / cost).toFixed(4); // give 4 decimal places to reduce losses            
        }

        return 0;
    }

    /**
     * @description Get the percentage by given rate
     * formula => Percentage = ( rate * 100) - 100
     * @param {Number} rate 
     */
    static percentageOfRate(rate) {
        if(+rate) {
            return +((rate * 100 ) - 100).toFixed(4);
        }

        return 0;
    }

    /**
     * @description Get the rate by given percentage
     * formula => Rate = (Percentage + 100 ) / 100
     * @param {Number} percentage 
     */
    static rateOfPercentage(percentage) {
        if(+percentage) {
            return +((percentage + 100) / 100).toFixed(4);
        }

        return 1;
    }


}