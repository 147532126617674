<template>
  <v-container>
    <v-card class="mb-4 quote-row-card" flat v-for="index in rows" :key="index">
      <v-row>
        <v-col cols="12" md="2">
          <v-skeleton-loader
            class="mx-auto"
            type="list-item-three-line"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="2">
          <v-skeleton-loader
            class="mx-auto"
            type="list-item-three-line, list-item-two-line"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="3">
          <v-skeleton-loader
            class="mx-auto"
            type="paragraph, paragraph"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="2">
          <v-skeleton-loader
            class="mx-auto"
            type="list-item-three-line"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="2">
          <v-skeleton-loader
            class="mx-auto"
            type="list-item-three-line, list-item-two-line"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="1">
          <v-skeleton-loader class="mx-auto" type="avatar"></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
export default {
  props: {
    rows: Number,
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
};
</script>
