<template>
  <v-row justify="center">
    <v-dialog
      :value="dialog"
      @input="$emit('input', $event)"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <v-icon class="mr-5">mdi-tune</v-icon
          ><span class="text-h5">Quotation - Advanced filter options</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <small>System Status</small>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-checkbox
                  v-model="systemStatus"
                  label="Draft"
                  value="1"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6" md="4">
               <v-checkbox
                  v-model="systemStatus"
                  label="On Approval"
                  value="4"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-checkbox
                  v-model="systemStatus"
                  label="Approved"
                  value="33"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-checkbox
                  v-model="systemStatus"
                  label="Rejected"
                  value="37"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6" md="4">
               <v-checkbox
                  v-model="systemStatus"
                  label="Void"
                  value="3"
                ></v-checkbox>
              </v-col>                        
            </v-row>
            
            <small>Flags</small>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-checkbox
                  v-model="isActive"
                  label="Active"                  
                ></v-checkbox>
              </v-col>
            </v-row>
             <small>By Dates</small>
             <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-menu
                      ref="createdDatesMenu"
                      v-model="createdDatesMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="createdDateRangeText"                          
                          label="Quote created between"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="green"
                        v-model="createdDates"
                        range
                        no-title
                        scrollable
                        @input="createdDatesMenu = false"
                      >
                      </v-date-picker> </v-menu
                  >
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-menu
                      ref="dueDatesMenu"
                      v-model="dueDatesMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dueDates"                          
                          label="Quote due between"
                          prepend-icon="mdi-calendar-clock"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="green"
                        v-model="dueDates"
                        range
                        no-title
                        scrollable
                        @input="dueDatesMenu = false"
                      >
                      </v-date-picker> </v-menu
                  >
              </v-col>
             </v-row>

          </v-container>          
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close"> Close </v-btn>
          <v-btn color="blue darken-1" text @click="close"> Apply Filters </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
  },
  emits: ["close-quote-filter-dialog"],
  data() {
    return {
      systemStatus: [1/**draft */,4/** on-approval */,33/** approved */, 3/** void or cancelled */ , 37 /** rejected */],
      isActive: true,   
      createdDatesMenu:null,
      createdDates:[],   
      dueDatesMenu:null,
      dueDates:[], 
      items: [],
    };
  },
  computed: {
    createdDateRangeText () {
        return this.createdDates.join(' ~ ')
      },
  },
  methods: {
    close() {
      this.$emit("close-quote-filter-dialog");
    },
  },
};
</script>
