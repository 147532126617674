export default class Product {
    uuid = '';
    code = '';
    name = '';
    description = '';
    imageUpload = '';
    imageSrc = '';
    imageLazySrc = '';
    //---------prices---------
    quantity = 1;
    costPrice = 0;
    markUpRate = 1;
    markUpPercentage = 0;
    markedUpPrice = 0;
    //---------extra prices--------
    discountPercentage = 0;
    discountedValue = 0;
    unitPriceAfterDiscounted = 0;
    freightCost = 0;
    freightUnitCost = 0;
    freightMarkUpRate = 1;
    freightMarkUpPercentage = 0;
    freightUnitMarkedUpPrice = 0;
    //-------price totals-----------
    sellingPriceSubtotal = 0;
    costPriceSubtotal = 0;
    profitMargin = 0;
    profitMarginPercentage = 0;

    priceMatrix = [];
    supplier = null;


}