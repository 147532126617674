<template>
  <v-form ref="headerform" @input="validateForm">
    <v-card flat>
      <v-card-text>
        <v-row>
          <v-col cols="12" xs="12" sm="12" md="6" lg="2">
            <v-text-field
              v-model="quoteNumber"
              :rules="quoteNumberRules"
              label="Quote Number"
              prepend-icon="mdi-hand-pointing-right"
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="6" lg="2">
            <v-text-field
              v-model="quoteName"
              :rules="quoteNameRules"
              label="Quote Name"
              prepend-icon="mdi-tag-outline"
            ></v-text-field>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="6" lg="2">
            <v-menu
              ref="createdAtMenu"
              v-model="createdAtMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="createdAt"
                  :rules="dateRules"
                  label="Created Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                color="green"
                v-model="createdAt"
                no-title
                scrollable
                @input="createdAtMenu = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="6" lg="2">
            <v-menu
              ref="dueAtMenu"
              v-model="dueAtMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dueAt"
                  :rules="dateRules"
                  label="Due Date"
                  prepend-icon="mdi-calendar-clock"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                color="green"
                v-model="dueAt"
                no-title
                scrollable
                @input="dueAtMenu = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="6" lg="2">
            <v-text-field
              v-model="amount"
              :rules="rules"
              label="Amount"
              readonly
              prepend-icon="mdi-currency-usd"
            ></v-text-field>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="6" lg="2">
            <v-text-field
              v-model="reference"
              :rules="referenceRules"
              label="Reference"
              prepend-icon="mdi-tag-text-outline"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>          
          <v-col cols="12" xs="12" sm="12" md="6" lg="4">
            <CustomerContactDialog
              v-on:itemSelected="setCustomerContact"
              class="px-2"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="customerContactName"
                  :rules="dialogSelectionRules"
                  v-bind="attrs"
                  v-on="on"
                  label="Customer Contact"
                  prepend-icon="mdi-account"
                  readonly
                  :hint="masterCustomerHint"
                ></v-text-field>
              </template>
            </CustomerContactDialog>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="6" lg="2">
            <SalespersonDialog v-on:itemSelected="setSalesperson" class="px-2">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="salespersonName"
                  v-bind="attrs"
                  v-on="on"
                  label="Salesperson"
                  prepend-icon="mdi-tie"
                  readonly
                ></v-text-field>
              </template>
            </SalespersonDialog>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="6" lg="2">
            <ProductionCoordinatorDialog
              v-on:itemSelected="setProductionCoordinator"
              class="px-2"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="productionCoordinatorName"
                  v-bind="attrs"
                  v-on="on"
                  label="Production Coordinator"
                  prepend-icon="mdi-phone-classic"
                  readonly
                ></v-text-field>
              </template>
            </ProductionCoordinatorDialog>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="6" lg="2">
            <v-text-field
              v-model="estimate"
              :rules="estimateRules"
              label="Sales Estimate"
              prepend-icon="mdi-calculator"
            ></v-text-field>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="6" lg="2">
            <v-icon>mdi-percent</v-icon>
            Probability
            <v-slider
              v-model="probability"
              :thumb-size="24"
              min="1"
              max="100"
              thumb-label="always"
              color="light-blue accent-3"
            ></v-slider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" xs="12" sm="12" md="6" lg="2"
            ><v-select
              v-model="selectStatus"
              :items="statuses"
              item-text="state"
              item-value="state"
              return-object
              label="Status"
              prepend-icon="mdi-pulse"
            ></v-select>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="6" lg="2">
            <v-select
              v-model="selectFollowUpStatus"
              :items="followUpstatuses"
              item-text="state"
              item-value="abbr"
              return-object
              label="Follow Up Status"
              prepend-icon="mdi-run"
            ></v-select
          ></v-col>
          <v-col cols="12" xs="12" sm="12" md="6" lg="2">
            <v-menu
              ref="followUpDateMenu"
              v-model="followUpDateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="followUpDate"
                  :rules="dateRules"
                  label="Follow Up Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                color="green"
                v-model="followUpDate"
                no-title
                scrollable
                @input="followUpDateMenu = false"
              >
              </v-date-picker> </v-menu
          ></v-col>
          <v-col>
            <v-textarea
          v-model="comments"
          label="Comments"   
          rows="2"       
          hint="Quotation Comments"
        ></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="5"
            xs="5"
            sm="5"
            md="5"
            lg="5"
            align="center"
            justify="center"
            ><v-divider></v-divider
          ></v-col>
          <v-col
            class="text-button"
            cols="5"
            xs="5"
            sm="5"
            md="5"
            lg="2"
            align="center"
            style="margin: 0; transform: translate(0%, -25%)"
          >
            <v-avatar color="light-green accent-4">
              <v-icon color="white">mdi-phone</v-icon>
            </v-avatar>
            Contact Information</v-col
          >
          <v-col
            cols="5"
            xs="5"
            sm="5"
            md="5"
            lg="5"
            align="center"
            justify="center"
            ><v-divider></v-divider
          ></v-col>
        </v-row>

        <v-row>
          <v-col cols="12" xs="12" sm="12" md="6" lg="6">
            <v-row class="d-flex flex-row mb-6 align-md-center my-1">
              <v-subheader class="text-overline">Office Address</v-subheader>
            </v-row>
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="12" lg="6"
                ><v-text-field
                  v-model="officeName"
                  :rules="addressNameRules"
                  label="Company"
                  prepend-icon="mdi-account-network"
                ></v-text-field
              ></v-col>
              <v-col cols="12" xs="12" sm="12" md="12" lg="6"
                ><v-text-field
                  v-model="officeContactName"
                  :rules="addressNameRules"
                  label="Contact Name"
                ></v-text-field
              ></v-col>
            </v-row>
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="12" lg="4"
                ><v-text-field
                  v-model="officeAddress1"
                  :rules="addressOneRules"
                  label="Address 1"
                  prepend-icon="mdi-map-marker-outline"
                ></v-text-field
              ></v-col>
              <v-col cols="12" xs="12" sm="12" md="12" lg="4"
                ><v-text-field
                  v-model="officeAddress2"
                  :rules="secondaryAddressRules"
                  label="Address 2"
                ></v-text-field
              ></v-col>
              <v-col cols="12" xs="12" sm="12" md="12" lg="4"
                ><v-text-field
                  v-model="officeAddress3"
                  :rules="secondaryAddressRules"
                  label="Address 3"
                ></v-text-field
              ></v-col>
            </v-row>
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="12" lg="4"
                ><v-text-field
                  v-model="officeSuburb"
                  :rules="suburbAddressRules"
                  label="Suburb"
                  prepend-icon="mdi-map-marker-outline"
                ></v-text-field
              >
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="12" lg="4">
                <v-text-field
                  v-model="officeSelectState"                  
                  label="State"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="12" lg="4"
                ><v-text-field
                  v-model="officePostcode"
                  :rules="postcodeRules"
                  label="Postcode"
                ></v-text-field
              ></v-col>
            </v-row>
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="12" lg="4"
                ><v-text-field
                  v-model="officeEmail"
                  :rules="emailRules"
                  label="Email"
                  prepend-icon="mdi-email-outline"
                ></v-text-field
              ></v-col>
              <v-col cols="12" xs="12" sm="12" md="12" lg="4"
                ><v-text-field
                  v-model="officeLandPhone"
                  :rules="phoneRules"
                  label="Land Phone"
                  prepend-icon="mdi-phone-classic"
                ></v-text-field
              ></v-col>
              <v-col cols="12" xs="12" sm="12" md="12" lg="4"
                ><v-text-field
                  v-model="officeMobilePhone"
                  :rules="phoneRules"
                  label="Mobile Phone"
                  prepend-icon="mdi-cellphone"
                ></v-text-field
              ></v-col>
            </v-row>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="6" lg="6">
            <v-row class="d-flex flex-row mb-6 align-md-center">
              <v-subheader class="text-overline">Shipping Address</v-subheader>
              <v-checkbox
                color="light-green accent-4"
                v-model="sameAsOfficeAddress"
                label="Same as office address"
                @click="sameAsOfficeAddressChecked"
              ></v-checkbox>
            </v-row>
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                <v-row>
                  <v-col cols="12" xs="12" sm="12" md="12" lg="6"
                    ><v-text-field
                      ref="shippingNameInput"
                      :value="
                        sameAsOfficeAddress
                          ? (shippingName = officeName)
                          : shippingName
                      "
                      :rules="addressNameRules"
                      label="Company"
                      prepend-icon="mdi-account-network"
                      @change="shippingNameInputChange"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="12" xs="12" sm="12" md="12" lg="6"
                    ><v-text-field
                      :value="
                        sameAsOfficeAddress
                          ? (shippingContactName = officeContactName)
                          : shippingContactName
                      "
                      :rules="addressNameRules"
                      label="Contact Name"
                      @change="shippingContactNameInputChange"
                    ></v-text-field
                  ></v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" xs="12" sm="12" md="12" lg="4"
                    ><v-text-field
                      :value="
                        sameAsOfficeAddress
                          ? (shippingAddress1 = officeAddress1)
                          : shippingAddress1
                      "
                      :rules="addressOneRules"
                      label="Address 1"
                      prepend-icon="mdi-map-marker-outline"
                      @change="shippingAddress1InputChange"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="12" xs="12" sm="12" md="12" lg="4"
                    ><v-text-field
                      :value="
                        sameAsOfficeAddress
                          ? (shippingAddress2 = officeAddress2)
                          : shippingAddress2
                      "
                      :rules="secondaryAddressRules"
                      label="Address 2"
                      @change="shippingAddress2InputChange"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="12" xs="12" sm="12" md="12" lg="4"
                    ><v-text-field
                      :value="
                        sameAsOfficeAddress
                          ? (shippingAddress3 = officeAddress3)
                          : shippingAddress3
                      "
                      :rules="secondaryAddressRules"
                      label="Address 3"
                      @change="shippingAddress3InputChange"
                    ></v-text-field
                  ></v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" xs="12" sm="12" md="12" lg="4"
                    ><v-text-field
                      :value="
                        sameAsOfficeAddress
                          ? (shippingSuburb = officeSuburb)
                          : shippingSuburb
                      "
                      :rules="suburbAddressRules"
                      label="Suburb"
                      prepend-icon="mdi-map-marker-outline"
                      @change="shippingSuburbInputChange"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="12" xs="12" sm="12" md="12" lg="4">
                    <v-text-field
                      :value="
                        sameAsOfficeAddress
                          ? (shippingSelectState = officeSelectState)
                          : shippingSelectState
                      "
                      
                      label="State"
                      @change="shippingSelectStateChange"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="12" lg="4"
                    ><v-text-field
                      :value="
                        sameAsOfficeAddress
                          ? (shippingPostcode = officePostcode)
                          : shippingPostcode
                      "
                      :rules="postcodeRules"
                      label="Postcode"
                      @change="shippingPostcodeChange"
                    ></v-text-field
                  ></v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" xs="12" sm="12" md="12" lg="4"
                    ><v-text-field
                      :value="
                        sameAsOfficeAddress
                          ? (shippingEmail = officeEmail)
                          : shippingEmail
                      "
                      :rules="emailRules"
                      label="Email"
                      prepend-icon="mdi-email-outline"
                      @change="shippingEmailChange"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="12" xs="12" sm="12" md="12" lg="4"
                    ><v-text-field
                      :value="
                        sameAsOfficeAddress
                          ? (shippingLandPhone = officeLandPhone)
                          : shippingLandPhone
                      "
                      :rules="phoneRules"
                      label="Land Phone"
                      prepend-icon="mdi-phone-classic"
                      @change="shippingLandPhoneChange"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="12" xs="12" sm="12" md="12" lg="4"
                    ><v-text-field
                      :value="
                        sameAsOfficeAddress
                          ? (shippingMobilePhone = officeMobilePhone)
                          : shippingMobilePhone
                      "
                      :rules="phoneRules"
                      label="Mobile Phone"
                      prepend-icon="mdi-cellphone"
                      @change="shippingMobilePhoneChange"
                    ></v-text-field
                  ></v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-form>
</template>
<script>
import CustomerContactDialog from "@/components/dialogs/CustomerContactDialog.vue";
import SalespersonDialog from "@/components/dialogs/SalespersonDialog.vue";
import ProductionCoordinatorDialog from "@/components/dialogs/ProductionCoordinatorDialog.vue";

export default {
  props: {
    quotationHeader: {
      // this will be quotation object from the api without other details
      type: Object,
      required: true,
    },
  },
  emits: ["quote-header-updated"],
  components: {
    CustomerContactDialog,
    SalespersonDialog,
    ProductionCoordinatorDialog,
  },  
  watch: {
    selectFollowUpStatus(newStatus) {
      console.log(newStatus);
    }
  },
  data() {
    return {
      toggle_multiple: [0, 1, 2],
      tab: null,
      formValidate: true,
      //-----------rule holder for stepper one  -------------------//
      stepperOneRule: [],

      //-------------QUOTE HEADER DATA-------------------//
      quoteNumber: this.quotationHeader?.number,
      quoteName: this.quotationHeader?.name,
      reference: this.quotationHeader?.reference,
      createdAtMenu: false,
      createdAt: this.quotationHeader?.createdAt,
      dueAtMenu: false,
      dueAt: this.quotationHeader?.dueAt,
      comments:this.quotationHeader?.comments,
      //-----------------------//
      masterCustomer: this.quotationHeader?.customer?.masterCustomer,
      masterCustomerName: this.quotationHeader?.customer?.masterCustomer?.name,
      customerContact: this.quotationHeader?.customer,
      customerContactName: this.quotationHeader?.customer?.name,
      salesperson: null,
      salespersonName:this.quotationHeader?.salesperson?.name,
      productionCoordinator: null,
      productionCoordinatorName:this.quotationHeader?.coordinator?.name,
      //--------------------------//
      amount: this.quotationHeader?.amountExTax,
      estimate: this.quotationHeader?.salesEstimate,
      probability: this.quotationHeader?.probability,
      //--------------------------//
      isActive: this.quotationHeader?.isActive,
      isOffshoreProduction: this.quotationHeader?.isOffshoreProduction,
      isUrgent: this.quotationHeader?.isUrgent,

      //-------------------------//
      selectStatus: { state: this.quotationHeader?.status, abbr: this.quotationHeader?.status},
      statuses:  this.quotationHeader?.statuses,
      selectFollowUpStatus: {
        state: this.quotationHeader?.crm?.followupStatus,
        abbr: this.quotationHeader?.crm?.followupStatusId,
      },
      followUpstatuses: this.quotationHeader?.followupStatuses,
      followUpDateMenu: null,
      followUpDate: this.quotationHeader?.crm?.followupOn,     
      //------------------------------------//
      officeName: "The promo lab",
      officeContactName: "Nick Whiteacre",
      officeAddress1: "Level 2",
      officeAddress2: "48 Greys Avenue",
      officeAddress3: "dicka place",
      officeSuburb: "Auckland",
      officeSelectState: '',
      officePostcode: "1010",
      officeEmail: "sarah.duffield@remax.co.nz",
      officeLandPhone: "09 600 1478",
      officeMobilePhone: "0278 757 575",
      //------------------------------------//
      sameAsOfficeAddress: false,
      shippingName: "RE/MAX New Zealand",
      shippingContactName: "Sarah Duffield",
      shippingAddress1: "Level 3/136 Broadway",
      shippingAddress2: "Newmarket",
      shippingAddress3: "",
      shippingSuburb: "Auckland",
      shippingSelectState: '',
      shippingPostcode: "1023",
      shippingEmail: "sarah.duffield@remax.co.nz",
      shippingLandPhone: "09 261 2555",
      shippingMobilePhone: "021 052 7012",
      //-------------------------------------//
      addressStatuses: '',
      
    };
  },
  computed: {
    masterCustomerHint(){
      return this.masterCustomer ? this.masterCustomer.name : '';
    },
    /** ---------RULES---------- */
    stepperOneRules() {
      return [() => this.formValidate];
    },
    quoteNumberRules() {
      const rules = [
        (value) => !!value || "Required.",
        (value) => (value || "").indexOf(" ") < 0 || "No spaces are allowed",
      ];

      return rules;
    },
    quoteNameRules() {
      const rules = [
        (v) =>
          !v || (v && v.length >= 3) || "Name must have atleast 3 characters",
        (v) =>
          !v ||
          (v && v.length <= 30) ||
          "This field exceeds maximum allowed characters 30",
      ];

      return rules;
    },
    dateRules() {
      const rules = [(value) => !!value || "Required."];

      return rules;
    },
    referenceRules() {
      const rules = [
        (value) =>
          !value || (value || "").indexOf(" ") < 0 || "No spaces are allowed",
        (v) =>
          !v ||
          (v && v.length >= 3) ||
          "Reference must have atleast 3 characters",
        (v) =>
          !v ||
          (v && v.length <= 20) ||
          "This field exceeds maximum allowed characters 20",
      ];

      return rules;
    },
    dialogSelectionRules() {
      const rules = [(value) => !!value || "Required."];

      return rules;
    },
    estimateRules() {
      const rules = [
        (value) => {
          const pattern = /^\d+(\.\d+)?$/;
          return !value || pattern.test(value) || "Invalid number.";
        },
      ];

      return rules;
    },

    addressNameRules() {
      const rules = [
        (value) => !!value || "Required.",
        (v) => (v && v.length >= 3) || "Name must have atleast 3 characters",
        (v) =>
          (v && v.length <= 50) ||
          "This field exceeds maximum allowed characters 50",
      ];

      return rules;
    },

    addressOneRules() {
      const rules = [
        (value) => !!value || "Required.",
        (v) => (v && v.length >= 3) || "Name must have atleast 3 characters",
        (v) =>
          (v && v.length <= 50) ||
          "This field exceeds maximum allowed characters 50",
      ];

      return rules;
    },
    secondaryAddressRules() {
      const rules = [
        (v) =>
          !v || (v && v.length >= 2) || "Name must have atleast 2 characters",
        (v) =>
          !v ||
          (v && v.length <= 30) ||
          "This field exceeds maximum allowed characters 30",
      ];

      return rules;
    },
    suburbAddressRules() {
      const rules = [
        (value) => {
          const pattern = /^[a-zA-Z\s]*$/;
          return !value || pattern.test(value) || "Only letters are allowed.";
        },
        (v) =>
          !v || (v && v.length >= 3) || "Suburb must have atleast 3 characters",
        (v) =>
          !v ||
          (v && v.length <= 20) ||
          "This field exceeds maximum allowed characters 20",
      ];

      return rules;
    },
    postcodeRules() {
      const rules = [
        (value) =>
          !value || (value || "").indexOf(" ") < 0 || "No spaces are allowed",
        (value) => {
          const pattern = /^\d+$/;
          return !value || pattern.test(value) || "Only numbers are allowed.";
        },
        (v) =>
          !v ||
          (v && v.length >= 4) ||
          "Postcode must have atleast 4 characters",
        (v) =>
          !v ||
          (v && v.length <= 10) ||
          "This field exceeds maximum allowed characters 10",
      ];

      return rules;
    },
    emailRules() {
      const rules = [(v) => !v || /.+@.+/.test(v) || "E-mail must be valid"];

      return rules;
    },
    phoneRules() {
      const rules = [
        (v) => {
          const numstr = v.replace(/\s/g, "");
          return (
            !v ||
            (numstr.length > 9) & (numstr.length < 13) ||
            "10 to 12 numbers are allowed"
          );
        },
        (v) =>
          !v ||
          /^(?=.*\d)[\d ]+$/.test(v) ||
          "Only numbers and spaces are allowed",
      ];

      return rules;
    },
  },
  methods: {
    setMasterCustomer(obj) {
      this.masterCustomer = obj.company;
    },
    setCustomerContact(obj) {      
      this.customerContact = obj;
      this.customerContactName = obj.name
    },
    setSalesperson(obj) {
      this.salesperson = obj;
      this.salespersonName = obj.name;
    },
    setProductionCoordinator(obj) {
      this.productionCoordinator = obj.username;
      this.productionCoordinatorName = obj.name;
    },
    validateForm() {
      this.formValidate = this.$refs.headerform.validate();
    },
    sameAsOfficeAddressChecked() {
      if (this.sameAsOfficeAddress) {
        this.copyOfficeAddressToShippingAddress();
      }
    },
    copyOfficeAddressToShippingAddress() {
      this.shippingName = this.officeName;
      this.shippingContactName = this.officeContactName;
      this.shippingAddress1 = this.officeAddress1;
      this.shippingAddress2 = this.officeAddress2;
      this.shippingAddress3 = this.officeAddress3;
      this.shippingSuburb = this.officeSuburb;
      this.shippingSelectState = this.officeSelectState;
      this.shippingPostcode = this.officePostcode;
      this.shippingEmail = this.officeEmail;
      this.shippingLandPhone = this.officeLandPhone;
      this.shippingMobilePhone = this.officeMobilePhone;
    },
    shippingNameInputChange(value) {
      this.shippingName = value;
    },
    shippingContactNameInputChange(value) {
      this.shippingContactName = value;
    },
    shippingAddress1InputChange(value) {
      this.shippingAddress1 = value;
    },
    shippingAddress2InputChange(value) {
      this.shippingAddress2 = value;
    },
    shippingAddress3InputChange(value) {
      this.shippingAddress3 = value;
    },
    shippingSuburbInputChange(value) {
      this.shippingSuburb = value;
    },
    shippingSelectStateChange(value) {
      this.shippingSelectState = value;
    },
    shippingPostcodeChange(value) {
      this.shippingPostcode = value;
      console.log(this.shippingSelectState);
    },
    shippingEmailChange(value) {
      this.shippingEmail = value;
    },
    shippingLandPhoneChange(value) {
      this.shippingLandPhone = value;
    },
    shippingMobilePhoneChange(value) {
      this.shippingMobilePhone = value;
    },
    quotationHeaderJsonBuilder() {
      return {
        id: this.quotationHeader.id,
        number: this.quotationHeader.number,
        name: this.quoteNumber,
        reference: this.reference,
        createdAt: this.createdAt,
        dueAt: this.dueAt,
        comments: this.comments,
        salesEstimate: this.estimate,
        probability: this.probability,       
        status: this?.selectStatus?.state,
        isActive: this.isActive,
        isOffshoreProduction: this.isOffshoreProduction,
        isUrgent: this.isUrgent,
        customer: {
          id: this?.customerContact?.id,
        },
        salesperson: {
          id: this?.salesperson?.id          
        },
        coordinator: {
          id: this?.productionCoordinator?.id
        },
        crm: {
          followupStatusId: this?.selectFollowUpStatus?.abbr,
          followupOn: this.followUpDate
        },
        addresses: {
          office: {
            companyName: this.officeName,
            contactName: this.officeContactName,
            address1: this.officeAddress1,
            address2: this.officeAddress2,
            address3: this.officeAddress3,
            suburb: this.officeSuburb,
            state: this?.officeSelectState?.abbr,
            postcode: this.officePostcode,
            email: this.officeEmail,
            phone1: this.officeLandPhone,
            phone2: this.officeMobilePhone,
          },
          shipping: {
            companyName: this.shippingName,
            contactName: this.shippingContactName,
            address1: this.shippingAddress1,
            address2: this.shippingAddress2,
            address3: this.shippingAddress3,
            suburb: this.shippingSuburb,
            state: this?.shippingSelectState?.abbr,
            postcode: this.shippingPostcode,
            email: this.shippingEmail,
            phone1: this.shippingLandPhone,
            phone2: this.shippingMobilePhone,
          },
        },
      };
    },
    formUpdate() {
      console.log("formUpdate");
      this.$emit('quote-header-updated', this.quotationHeaderJsonBuilder());
    },
  },
  updated() {    
    this.formUpdate();
    //console.log('form updated detected');
  },
  created() {
    //console.log('created header form', this.quotationHeader);
  },
  mounted() {
    //console.log('mounted header form', this.quotationHeader);
  },
};
</script>
