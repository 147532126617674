<template>
  <v-row justify="center">
    <v-dialog
      :value="dialog"
      @input="$emit('input', $event)"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
    >
      <v-card>
        <v-toolbar dark color="light-green accent-4">
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Item Price Playing Calculator</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="emitSelected" :disabled="!isSelected">
              Save
              <v-icon right dark>mdi-content-save</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container class="my-5">
          <v-layout row>
            <v-flex md12 lg12>
              <v-row>
                <v-col cols="12">
                  <v-stepper flat v-model="stepper">
                    <v-stepper-header>
                      <v-stepper-step
                        color="light-green accent-4"
                        step="1"
                        editable
                      >
                        <span class="text-uppercase">Add product</span>
                      </v-stepper-step>

                      <v-divider></v-divider>

                      <v-stepper-step step="2" editable>
                        <span class="text-uppercase">Add decorations</span>
                      </v-stepper-step>

                      <v-divider></v-divider>

                      <v-stepper-step step="3" editable>
                        <span class="text-uppercase">Add other costs</span>
                      </v-stepper-step>

                      <v-divider></v-divider>

                      <v-stepper-step step="4" editable>
                        <span class="text-uppercase">Add size matrix</span>
                      </v-stepper-step>

                      <v-divider></v-divider>

                      <v-stepper-step step="5" editable :complete="stepper">
                        <span class="text-uppercase">Price Player</span>
                      </v-stepper-step>
                    </v-stepper-header>

                    <v-stepper-content step="1">
                      <ProductCustomiseForm
                        :cal-quantity="quantitySlider"
                        :cal-product-cost="productCost"
                        :cal-product-markup-rate="productMarkUpRate"
                        :cal-product-markup-percentage="productMarkUpPercentage"
                        :cal-product-markedup-price="productMarkedUpPrice"
                        @productUpdated="setProduct"
                      />
                    </v-stepper-content>
                    <v-stepper-content step="5">
                      <v-card flat class="mx-auto my-2">
                        <v-card-text>
                          <v-row class="align-center">
                            <v-col cols="2" class="text-right"
                              ><v-icon color="red accent-2"
                                >mdi-basket-fill</v-icon
                              >&nbsp;<span class="overline ml-2">Quantity</span>
                            </v-col>
                            <v-col cols="10">
                              <v-container row class="align-center">
                                <v-flex xs6 sm6 md2 lg2>
                                  <v-text-field
                                    v-model="quantitySlider"
                                    class="text-h4"
                                    required
                                  >
                                    <template v-slot:prepend
                                      ><v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="decrementQty"
                                      >
                                        <v-icon>mdi-minus</v-icon>
                                      </v-btn></template
                                    >
                                    <template v-slot:append>
                                      <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="incrementQty"
                                      >
                                        <v-icon>mdi-plus</v-icon>
                                      </v-btn>
                                    </template>
                                  </v-text-field>
                                </v-flex>
                              </v-container>
                            </v-col>
                          </v-row>

                          <v-row class="align-center">
                            <v-col cols="2" class="text-right"
                              ><v-icon color="green lighten-2"
                                >mdi-tshirt-v</v-icon
                              >&nbsp;<span class="overline ml-2">Product</span>
                            </v-col>
                            <v-col cols="10">
                              <v-container row class="align-center">
                                <v-flex xs6 sm6 md2 lg2>
                                  <v-text-field
                                    v-model="productCost"
                                    required
                                    prefix="$"
                                    label="Product Cost"
                                  >
                                  </v-text-field>
                                </v-flex>
                                <v-flex xs6 sm6 md2 lg2>
                                  <v-text-field
                                    v-model="productMarkUpRate"
                                    required
                                    label="Mark up Rate"
                                    class="mr-2"
                                    prepend-icon="mdi-plus mdi-rotate-45"
                                  >
                                  </v-text-field>
                                </v-flex>
                                <v-flex xs6 sm6 md2 lg2>
                                  <v-text-field
                                    v-model="productMarkUpPercentage"
                                    required
                                    label="Mark up Percentage"
                                    suffix="%"
                                    class="mr-2"
                                    readonly
                                  >
                                  </v-text-field>
                                </v-flex>
                                <v-flex xs6 sm6 md2 lg2>
                                  <v-text-field
                                    v-model="productMarkedUpPrice"
                                    required
                                    label="Marked up Price"
                                    prefix="$"
                                    prepend-icon="mdi-equal"
                                  >
                                  </v-text-field>
                                </v-flex>
                              </v-container>
                            </v-col>
                          </v-row>

                          <v-row class="align-center">
                            <v-col cols="2" class="text-right"
                              ><v-icon color="orange darken-4" small
                                >mdi-gift</v-icon
                              >&nbsp;<span class="overline ml-2">Discount</span>
                            </v-col>
                            <v-col cols="10">
                              <v-container row class="align-center">
                                <v-flex xs6 sm6 md2 lg2>
                                  <v-text-field
                                    v-model="productMarkedUpPrice"
                                    required
                                    prefix="$"
                                    label="Marked up Price"
                                  >
                                  </v-text-field>
                                </v-flex>
                                <v-flex xs6 sm6 md2 lg2>
                                  <v-text-field
                                    v-model="productDiscountPercentage"
                                    required
                                    label="Discount Percentage"
                                    class="mr-2"
                                    prepend-icon="mdi-plus mdi-rotate-45"
                                    suffix="%"
                                  >
                                  </v-text-field>
                                </v-flex>
                                <v-flex xs6 sm6 md2 lg2>
                                  <v-text-field
                                    v-model="productDiscountedValue"
                                    required
                                    label="Discounted Value"
                                    prefix="$"
                                    prepend-icon="mdi-minus"
                                  >
                                  </v-text-field>
                                </v-flex>
                                <v-flex xs6 sm6 md2 lg2>
                                  <v-text-field
                                    v-model="productUnitPriceAfterDiscounted"
                                    required
                                    label="Discounted Price"
                                    prefix="$"
                                    prepend-icon="mdi-equal"
                                  >
                                  </v-text-field>
                                </v-flex>
                              </v-container>
                            </v-col>
                          </v-row>

                          <v-row class="align-center">
                            <v-col cols="2" class="text-right"
                              ><v-icon color="blue-grey lighten-1" small
                                >mdi-truck-fast</v-icon
                              >&nbsp;<span class="overline ml-2">Freight</span>
                            </v-col>
                            <v-col cols="10">
                              <v-container row class="align-center">
                                <v-flex xs6 sm6 md2 lg2>
                                  <v-text-field
                                    v-model="productFreightCost"
                                    required
                                    prefix="$"
                                    label="Freight Cost"
                                  >
                                  </v-text-field>
                                </v-flex>
                                <v-flex xs6 sm6 md1 lg1>
                                  <v-text-field
                                    v-model="quantitySlider"
                                    required
                                    label="Quantity"
                                    class="mr-2"
                                    prepend-icon="mdi-division"
                                    readonly
                                  >
                                  </v-text-field>
                                </v-flex>
                                <v-flex xs6 sm6 md2 lg2>
                                  <v-text-field
                                    v-model="productFreightUnitCost"
                                    required
                                    label="Freight Unit Cost"
                                    prefix="$"
                                    prepend-icon="mdi-equal"
                                  >
                                  </v-text-field>
                                </v-flex>
                                <v-flex xs6 sm6 md2 lg2>
                                  <v-text-field
                                    v-model="productFreightMarkUpRate"
                                    required
                                    label="Mark up Rate"
                                    class="mr-2"
                                    prepend-icon="mdi-plus mdi-rotate-45"
                                  >
                                  </v-text-field>
                                </v-flex>
                                <v-flex xs6 sm6 md2 lg2>
                                  <v-text-field
                                    v-model="productFreightMarkUpPercentage"
                                    required
                                    label="Mark up Percentage"
                                    suffix="%"
                                    class="mr-2"
                                  >
                                  </v-text-field>
                                </v-flex>
                                <v-flex xs6 sm6 md2 lg2>
                                  <v-text-field
                                    v-model="productFreightUnitMarkedUpPrice"
                                    required
                                    label="Freight Unit Marked up"
                                    prefix="$"
                                    prepend-icon="mdi-equal"
                                  >
                                  </v-text-field>
                                </v-flex>
                              </v-container>
                            </v-col>
                          </v-row>
                          <v-row class="align-center">
                            <v-col cols="2" class="text-right"
                              ><v-icon small color="red accent-2"
                                >mdi-sigma</v-icon
                              >&nbsp;<span class="overline ml-2">Subtotal</span>
                            </v-col>
                            <v-col cols="10">
                              <v-container row class="align-center">
                                <v-flex xs6 sm6 md2 lg2>
                                  <v-text-field
                                    v-model="productPriceSubtotal"
                                    required
                                    prefix="$"
                                    label="Product Unit Price"
                                  >
                                  </v-text-field>
                                </v-flex>
                                <v-flex xs6 sm6 md2 lg2>
                                  <v-text-field
                                    v-model="productCostSubtotal"
                                    required
                                    label="Product Unit Cost"
                                    prefix="$"
                                    prepend-icon="mdi-minus"
                                  >
                                  </v-text-field>
                                </v-flex>
                                <v-flex xs6 sm6 md2 lg2>
                                  <v-text-field
                                    v-model="productProfitMargin"
                                    required
                                    label="Profit Margin"
                                    prefix="$"
                                    prepend-icon="mdi-equal"
                                  >
                                  </v-text-field>
                                </v-flex>
                                <v-flex xs6 sm6 md2 lg2>
                                  <v-text-field
                                    v-model="productProfitMarginPercentage"
                                    required
                                    label="Margin Percentage"
                                    suffix="%"
                                    prepend-icon="mdi-equal"
                                  >
                                  </v-text-field>
                                </v-flex>
                              </v-container>
                            </v-col>
                          </v-row>
                          <v-divider></v-divider>
                          <v-expansion-panels accordion>
                            <v-expansion-panel>
                              <v-expansion-panel-header>
                                <span class="d-block"
                                  ><v-icon color="purple darken-2" small
                                    >mdi-tag-plus</v-icon
                                  >&nbsp;Decoration Cositng</span
                                >
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <!-- LOOP DECORATIONS CAL INPUTS -->
                                <v-row class="align-center mt-2">
                                  <v-col
                                    cols="2"
                                    class="text-right text-uppercase"
                                  >
                                    <span class="d-block"
                                      ><v-icon color="purple darken-2" small
                                        >mdi-tag-plus</v-icon
                                      ></span
                                    >
                                    <span class="caption ml-2 d-block"
                                      >116646_TNZ</span
                                    >
                                    <span class="d-block"
                                      >1 Colour 1 Position Screen Print</span
                                    >
                                  </v-col>
                                  <v-col cols="10">
                                    <v-container row class="align-center">
                                      <v-row class="align-center">
                                        <v-flex xs6 sm6 md2 lg2>
                                          <v-text-field
                                            v-model="
                                              productFreightUnitMarkedUpPrice
                                            "
                                            required
                                            label="Unit Cost"
                                            prefix="$"
                                          >
                                          </v-text-field>
                                        </v-flex>
                                        <v-flex xs6 sm6 md2 lg2>
                                          <v-text-field
                                            v-model="
                                              productFreightUnitMarkedUpPrice
                                            "
                                            required
                                            label="Mark up Rate"
                                            prepend-icon="mdi-plus mdi-rotate-45"
                                          >
                                          </v-text-field>
                                        </v-flex>
                                        <v-flex xs6 sm6 md2 lg2 class="ml-2">
                                          <v-text-field
                                            v-model="
                                              productFreightUnitMarkedUpPrice
                                            "
                                            required
                                            label="Mark up Percentage"
                                            suffix="%"
                                          >
                                          </v-text-field>
                                        </v-flex>
                                        <v-flex xs6 sm6 md2 lg2>
                                          <v-text-field
                                            v-model="
                                              productFreightUnitMarkedUpPrice
                                            "
                                            required
                                            label="Marked up Price"
                                            prefix="$"
                                            prepend-icon="mdi-equal"
                                          >
                                          </v-text-field>
                                        </v-flex>
                                      </v-row>
                                      <v-row class="align-center">
                                        <v-flex xs6 sm6 md2 lg2>
                                          <v-text-field
                                            v-model="productFreightCost"
                                            required
                                            prefix="$"
                                            label="Setup Cost"
                                          >
                                          </v-text-field>
                                        </v-flex>
                                        <v-flex xs6 sm6 md1 lg1>
                                          <v-text-field
                                            v-model="quantitySlider"
                                            required
                                            label="Quantity"
                                            class="mr-2"
                                            prepend-icon="mdi-division"
                                            readonly
                                          >
                                          </v-text-field>
                                        </v-flex>
                                        <v-flex xs6 sm6 md2 lg2>
                                          <v-text-field
                                            v-model="productFreightUnitCost"
                                            required
                                            label="Setup Unit Cost"
                                            prefix="$"
                                            prepend-icon="mdi-equal"
                                          >
                                          </v-text-field>
                                        </v-flex>
                                        <v-flex xs6 sm6 md2 lg2>
                                          <v-text-field
                                            v-model="productFreightMarkUpRate"
                                            required
                                            label="Mark up Rate"
                                            class="mr-2"
                                            prepend-icon="mdi-plus mdi-rotate-45"
                                          >
                                          </v-text-field>
                                        </v-flex>
                                        <v-flex xs6 sm6 md2 lg2>
                                          <v-text-field
                                            v-model="
                                              productFreightMarkUpPercentage
                                            "
                                            required
                                            label="Mark up Percentage"
                                            suffix="%"
                                            class="mr-2"
                                          >
                                          </v-text-field>
                                        </v-flex>
                                        <v-flex xs6 sm6 md2 lg2>
                                          <v-text-field
                                            v-model="
                                              productFreightUnitMarkedUpPrice
                                            "
                                            required
                                            label="Setup Unit Marked up"
                                            prefix="$"
                                            prepend-icon="mdi-equal"
                                          >
                                          </v-text-field>
                                        </v-flex>
                                      </v-row>
                                    </v-container>
                                  </v-col>
                                </v-row>
                                <v-divider></v-divider>
                                <v-row class="align-center mt-2">
                                  <v-col
                                    cols="2"
                                    class="text-right text-uppercase"
                                  >
                                    <span class="d-block"
                                      ><v-icon color="purple darken-2" small
                                        >mdi-tag-plus</v-icon
                                      ></span
                                    >
                                    <span class="caption ml-2 d-block"
                                      >116221C9</span
                                    >
                                    <span class="d-block"
                                      >1 Colour 1 Position Screen Print</span
                                    >
                                  </v-col>
                                  <v-col cols="10">
                                    <v-container row class="align-center">
                                      <v-row class="align-center">
                                        <v-flex xs6 sm6 md2 lg2>
                                          <v-text-field
                                            v-model="
                                              productFreightUnitMarkedUpPrice
                                            "
                                            required
                                            label="Unit Cost"
                                            prefix="$"
                                          >
                                          </v-text-field>
                                        </v-flex>
                                        <v-flex xs6 sm6 md2 lg2>
                                          <v-text-field
                                            v-model="
                                              productFreightUnitMarkedUpPrice
                                            "
                                            required
                                            label="Mark up Rate"
                                            prepend-icon="mdi-plus mdi-rotate-45"
                                          >
                                          </v-text-field>
                                        </v-flex>
                                        <v-flex xs6 sm6 md2 lg2 class="ml-2">
                                          <v-text-field
                                            v-model="
                                              productFreightUnitMarkedUpPrice
                                            "
                                            required
                                            label="Mark up Percentage"
                                            suffix="%"
                                          >
                                          </v-text-field>
                                        </v-flex>
                                        <v-flex xs6 sm6 md2 lg2>
                                          <v-text-field
                                            v-model="
                                              productFreightUnitMarkedUpPrice
                                            "
                                            required
                                            label="Marked up Price"
                                            prefix="$"
                                            prepend-icon="mdi-equal"
                                          >
                                          </v-text-field>
                                        </v-flex>
                                      </v-row>
                                      <v-row class="align-center">
                                        <v-flex xs6 sm6 md2 lg2>
                                          <v-text-field
                                            v-model="productFreightCost"
                                            required
                                            prefix="$"
                                            label="Setup Cost"
                                          >
                                          </v-text-field>
                                        </v-flex>
                                        <v-flex xs6 sm6 md1 lg1>
                                          <v-text-field
                                            v-model="quantitySlider"
                                            required
                                            label="Quantity"
                                            class="mr-2"
                                            prepend-icon="mdi-division"
                                            readonly
                                          >
                                          </v-text-field>
                                        </v-flex>
                                        <v-flex xs6 sm6 md2 lg2>
                                          <v-text-field
                                            v-model="productFreightUnitCost"
                                            required
                                            label="Setup Unit Cost"
                                            prefix="$"
                                            prepend-icon="mdi-equal"
                                          >
                                          </v-text-field>
                                        </v-flex>
                                        <v-flex xs6 sm6 md2 lg2>
                                          <v-text-field
                                            v-model="productFreightMarkUpRate"
                                            required
                                            label="Mark up Rate"
                                            class="mr-2"
                                            prepend-icon="mdi-plus mdi-rotate-45"
                                          >
                                          </v-text-field>
                                        </v-flex>
                                        <v-flex xs6 sm6 md2 lg2>
                                          <v-text-field
                                            v-model="
                                              productFreightMarkUpPercentage
                                            "
                                            required
                                            label="Mark up Percentage"
                                            suffix="%"
                                            class="mr-2"
                                          >
                                          </v-text-field>
                                        </v-flex>
                                        <v-flex xs6 sm6 md2 lg2>
                                          <v-text-field
                                            v-model="
                                              productFreightUnitMarkedUpPrice
                                            "
                                            required
                                            label="Setup Unit Marked up"
                                            prefix="$"
                                            prepend-icon="mdi-equal"
                                          >
                                          </v-text-field>
                                        </v-flex>
                                      </v-row>
                                    </v-container>
                                  </v-col>
                                </v-row>
                                <!-- END DECORATIONS LOOP -->

                                <v-row class="align-center">
                                  <v-col cols="2" class="text-right"
                                    ><v-icon small color="purple darken-2"
                                      >mdi-sigma</v-icon
                                    >&nbsp;<span class="overline ml-2"
                                      >Subtotal</span
                                    >
                                  </v-col>
                                  <v-col cols="10">
                                    <v-container row class="align-center">
                                      <v-flex xs6 sm6 md2 lg2>
                                        <v-text-field
                                          v-model="decorationPriceSubtotal"
                                          required
                                          prefix="$"
                                          label="Decoration Price"
                                        >
                                        </v-text-field>
                                      </v-flex>
                                      <v-flex xs6 sm6 md2 lg2>
                                        <v-text-field
                                          v-model="decorationCostSubtotal"
                                          required
                                          label="Decoration Cost"
                                          prefix="$"
                                          prepend-icon="mdi-minus"
                                        >
                                        </v-text-field>
                                      </v-flex>
                                      <v-flex xs6 sm6 md2 lg2>
                                        <v-text-field
                                          v-model="decorationProfitMargin"
                                          required
                                          label="Profit Margin"
                                          prefix="$"
                                          prepend-icon="mdi-equal"
                                        >
                                        </v-text-field>
                                      </v-flex>
                                      <v-flex xs6 sm6 md2 lg2>
                                        <v-text-field
                                          v-model="
                                            decorationProfitMarginPercentage
                                          "
                                          required
                                          label="Margin Percentage"
                                          suffix="%"
                                          prepend-icon="mdi-equal"
                                        >
                                        </v-text-field>
                                      </v-flex>
                                    </v-container>
                                  </v-col>
                                </v-row>
                                <!-- END DECORATIONS SUBTOTAL -->
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                              <v-expansion-panel-header>
                                <span class="d-block"
                                  ><v-icon small color="teal darken-1"
                                    >mdi-more mdi-rotate-45</v-icon
                                  >&nbsp;Other Cositng</span
                                >
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <!-- LOOP OTHER COSTS -->
                                <v-row class="align-center mt-2">
                                  <v-col
                                    cols="2"
                                    class="text-right text-uppercase"
                                  >
                                    <span class="caption ml-2 d-block"
                                      ><v-icon small color="teal darken-1"
                                        >mdi-more mdi-rotate-45</v-icon
                                      ></span
                                    >
                                    <span class="caption ml-2 d-block"
                                      >Freight - Freight from Supplier</span
                                    >
                                    <span class="d-block">Qty based</span>
                                  </v-col>
                                  <v-col cols="10">
                                    <v-container row class="align-center">
                                      <v-row class="align-center">
                                        <v-flex xs6 sm6 md2 lg2>
                                          <v-text-field
                                            v-model="productFreightCost"
                                            required
                                            prefix="$"
                                            label="Other Cost"
                                          >
                                          </v-text-field>
                                        </v-flex>
                                        <v-flex xs6 sm6 md1 lg1>
                                          <v-text-field
                                            v-model="quantitySlider"
                                            required
                                            label="Quantity"
                                            class="mr-2"
                                            prepend-icon="mdi-division"
                                            readonly
                                          >
                                          </v-text-field>
                                        </v-flex>
                                        <v-flex xs6 sm6 md2 lg2>
                                          <v-text-field
                                            v-model="productFreightUnitCost"
                                            required
                                            label="Unit Cost"
                                            prefix="$"
                                            prepend-icon="mdi-equal"
                                          >
                                          </v-text-field>
                                        </v-flex>
                                        <v-flex xs6 sm6 md2 lg2>
                                          <v-text-field
                                            v-model="productFreightMarkUpRate"
                                            required
                                            label="Mark up Rate"
                                            class="mr-2"
                                            prepend-icon="mdi-plus mdi-rotate-45"
                                          >
                                          </v-text-field>
                                        </v-flex>
                                        <v-flex xs6 sm6 md2 lg2>
                                          <v-text-field
                                            v-model="
                                              productFreightMarkUpPercentage
                                            "
                                            required
                                            label="Mark up Percentage"
                                            suffix="%"
                                            class="mr-2"
                                          >
                                          </v-text-field>
                                        </v-flex>
                                        <v-flex xs6 sm6 md2 lg2>
                                          <v-text-field
                                            v-model="
                                              productFreightUnitMarkedUpPrice
                                            "
                                            required
                                            label="Other Cost Marked up"
                                            prefix="$"
                                            prepend-icon="mdi-equal"
                                          >
                                          </v-text-field>
                                        </v-flex>
                                      </v-row>
                                    </v-container>
                                  </v-col>
                                </v-row>
                                <!-- END OTHER COST LOOP -->
                                <v-row class="align-center">
                                  <v-col cols="2" class="text-right"
                                    ><v-icon small color="teal darken-1"
                                      >mdi-sigma</v-icon
                                    >&nbsp;<span class="overline ml-2"
                                      >Subtotal</span
                                    >
                                  </v-col>
                                  <v-col cols="10">
                                    <v-container row class="align-center">
                                      <v-flex xs6 sm6 md2 lg2>
                                        <v-text-field
                                          v-model="othercostPriceSubtotal"
                                          required
                                          prefix="$"
                                          label="Other Cost Price"
                                        >
                                        </v-text-field>
                                      </v-flex>
                                      <v-flex xs6 sm6 md2 lg2>
                                        <v-text-field
                                          v-model="othercostCostSubtotal"
                                          required
                                          label="Other Cost - Cost"
                                          prefix="$"
                                          prepend-icon="mdi-minus"
                                        >
                                        </v-text-field>
                                      </v-flex>
                                      <v-flex xs6 sm6 md2 lg2>
                                        <v-text-field
                                          v-model="othercostProfitMargin"
                                          required
                                          label="Profit Margin"
                                          prefix="$"
                                          prepend-icon="mdi-equal"
                                        >
                                        </v-text-field>
                                      </v-flex>
                                      <v-flex xs6 sm6 md2 lg2>
                                        <v-text-field
                                          v-model="
                                            othercostProfitMarginPercentage
                                          "
                                          required
                                          label="Margin Percentage"
                                          suffix="%"
                                          prepend-icon="mdi-equal"
                                        >
                                        </v-text-field>
                                      </v-flex>
                                    </v-container>
                                  </v-col>
                                </v-row>
                                <!-- END OTHER COST SUBTOTAL -->
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                          <v-divider></v-divider>
                          <!--TOTAL SUMMARY -->
                          <v-row class="align-center mt-2">
                            <v-col cols="2" class="text-right"
                              ><v-icon color="red accent-2"
                                >mdi-hand-pointing-right</v-icon
                              >&nbsp;<span class="overline ml-2"
                                >Selling price</span
                              >
                              &nbsp;
                              <v-icon
                                aria-label="Lock sell price"
                                @click="lockUnitSellPrice = !lockUnitSellPrice"
                                style="cursor: pointer"
                                :class="{
                                  'red--text': lockUnitSellPrice,
                                }"
                                >{{
                                  lockUnitSellPrice
                                    ? "mdi-lock"
                                    : "mdi-lock-open"
                                }}</v-icon
                              >
                            </v-col>
                            <v-col cols="10">
                              <v-container row class="align-center">
                                <v-flex xs6 sm6 md2 lg2>
                                  <v-text-field
                                    v-model="unitSellPrice"
                                    required
                                    prefix="$"
                                    label="Unit Selling Price"
                                  >
                                  </v-text-field>
                                </v-flex>
                                <v-flex xs6 sm6 md2 lg2>
                                  <v-text-field
                                    v-model="unitCostPrice"
                                    required
                                    label="Unit Cost Price"
                                    prefix="$"
                                    prepend-icon="mdi-minus"
                                  >
                                  </v-text-field>
                                </v-flex>
                                <v-flex xs6 sm6 md2 lg2>
                                  <v-text-field
                                    v-model="unitProfitMargin"
                                    required
                                    label="Profit Margin"
                                    prefix="$"
                                    prepend-icon="mdi-equal"
                                  >
                                  </v-text-field>
                                </v-flex>
                                <v-flex xs6 sm6 md2 lg2>
                                  <v-text-field
                                    v-model="unitProfitPercentage"
                                    required
                                    label="Margin Percentage"
                                    suffix="%"
                                    prepend-icon="mdi-equal"
                                  >
                                  </v-text-field>
                                </v-flex>
                              </v-container>
                            </v-col>
                          </v-row>
                          <v-row class="align-center">
                            <v-col cols="2" class="text-right">
                              <v-icon color="red accent-2">mdi-sigma</v-icon>
                              &nbsp;
                              <span class="overline ml-2">By Qty</span>
                              &nbsp; &nbsp;
                              <span class="display-1 ml-2"
                                >x&nbsp;{{ quantitySlider }}</span
                              >
                            </v-col>
                            <v-col cols="10">
                              <v-container row class="align-center">
                                <v-flex xs6 sm6 md2 lg2>
                                  <v-text-field
                                    v-model="unitSellPriceAmount"
                                    required
                                    prefix="$"
                                    label="Selling Price Amount"
                                  >
                                  </v-text-field>
                                </v-flex>
                                <v-flex xs6 sm6 md2 lg2>
                                  <v-text-field
                                    v-model="unitCostPriceAmount"
                                    required
                                    label="Cost Price Amount"
                                    prefix="$"
                                    prepend-icon="mdi-minus"
                                  >
                                  </v-text-field>
                                </v-flex>
                                <v-flex xs6 sm6 md2 lg2>
                                  <v-text-field
                                    v-model="profitMarginAmount"
                                    required
                                    label="Profit Margin Amount"
                                    prefix="$"
                                    prepend-icon="mdi-equal"
                                  >
                                  </v-text-field>
                                </v-flex>
                                <v-flex xs6 sm6 md2 lg2>
                                  <v-text-field
                                    v-model="profitMarginPercentage"
                                    required
                                    label="Margin Percentage"
                                    suffix="%"
                                    prepend-icon="mdi-equal"
                                  >
                                  </v-text-field>
                                </v-flex>
                              </v-container>
                            </v-col>
                          </v-row>
                          <v-row class="align-center">
                            <v-col cols="2" class="text-right"
                              ><v-icon small color="red accent-2"
                                >mdi-truck-fast</v-icon
                              >&nbsp;<span class="overline ml-2">Freight</span>
                            </v-col>
                            <v-col cols="10">
                              <v-container row class="align-center">
                                <v-flex xs6 sm6 md2 lg2>
                                  <v-text-field
                                    v-model="unitSellPrice"
                                    required
                                    prefix="$"
                                    label="Unit Selling Price"
                                  >
                                  </v-text-field>
                                </v-flex>
                                <v-flex xs6 sm6 md2 lg2>
                                  <v-text-field
                                    v-model="finalFreightRate"
                                    required
                                    label="Freight Mark up Rate"
                                    prepend-icon="mdi-plus mdi-rotate-45"
                                  >
                                  </v-text-field>
                                </v-flex>
                                <v-flex xs6 sm6 md2 lg2>
                                  <v-text-field
                                    v-model="unitSellPriceWithFreight"
                                    required
                                    label="Unit Price with Freight"
                                    prefix="$"
                                    prepend-icon="mdi-equal"
                                  >
                                  </v-text-field>
                                </v-flex>
                              </v-container>
                            </v-col>
                          </v-row>
                          <v-row class="align-center">
                            <v-col cols="2" class="text-right"
                              ><v-icon color="red accent-2">mdi-gavel</v-icon
                              >&nbsp;<span class="overline ml-2">Tax</span>
                            </v-col>
                            <v-col cols="10">
                              <v-container row class="align-center">
                                <v-flex xs6 sm6 md2 lg2 class="mr-2">
                                  <v-text-field
                                    v-model="unitSellPriceWithFreight"
                                    required
                                    prefix="$"
                                    label="Selling Price with Freight"
                                  >
                                  </v-text-field>
                                </v-flex>
                                <v-flex xs6 sm6 md2 lg2>
                                  <v-select
                                    v-model="selectedTax"
                                    :items="taxTypes"
                                    label="Tax Type"
                                    item-text="type"
                                    item-value="abbr"
                                    prepend-icon=""
                                  ></v-select>
                                </v-flex>
                                <v-flex xs6 sm6 md2 lg2>
                                  <v-text-field
                                    v-model="unitSellPriceWithTax"
                                    required
                                    label="Unit Sell Price with TAX"
                                    prefix="$"
                                    prepend-icon="mdi-plus mdi-rotate-45"
                                  >
                                  </v-text-field>
                                </v-flex>
                              </v-container>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-stepper-content>
                  </v-stepper>
                </v-col>
              </v-row>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import ProductCustomiseForm from "../fragments/ProductCustomiseForm.vue";
import Product from "../../models/product.js";
export default {
  components: {
    ProductCustomiseForm,
  },
  props: {
    editedId: Number,
    dialog: Boolean,
  },
  emits: ["close-dialog","emitSelected"],
  data() {
    return {
      stepper: 1,
      //--------------------
      itemDetailObj: null,
      quantitySlider: 50,
      quantitySliderMin: 1,

      //quote item input fields
      product: null,
      productCost: 1,
      productMarkUpRate: 1,
      productMarkUpPercentage: 0,
      productMarkedUpPrice: 1,

      productDiscountPercentage: 0,
      productDiscountedValue: 0,
      productUnitPriceAfterDiscounted: 0,

      productFreightCost: 0,
      productFreightUnitCost: 0,
      productFreightMarkUpRate: 1,
      productFreightMarkUpPercentage: 0,
      productFreightUnitMarkedUpPrice: 0,

      productPriceSubtotal: 0,
      productCostSubtotal: 0,
      productProfitMargin: 0,
      productProfitMarginPercentage: 0,
      //-------decoration data --------------------
      decorations: [
        {
          uuid: "1234",
          code: "116221C9",
          name: "Embroidery Per Position (up To 10,000 Stitches)",
          decorationCost: 0,
          decorationMarkUpRate: 0,
          decorationMarkUpPercentage: 0,
          decorationMarkedUpPrice: 0,
          logoSetupCost: 100,
          logoSetupUnitCost: 10,
          logoSetupUnitMarkUpRate: 0,
          logoSetupUnitMarkUpPercentage: 0,
          logoSetupUnitMarkedUpPrice: 0,
        },
      ],
      decorationPriceSubtotal: 0,
      decorationCostSubtotal: 0,
      decorationProfitMargin: 0,
      decorationProfitMarginPercentage: 0,
      //-------other-cost data --------------------
      othercosts: [
        {
          uuid: "1234",
          group: "Feight",
          name: "Freight from Supplier",
          qtyBased: true,
          othercostCost: 100,
          othercostUnitCost: 10,
          othercostUnitMarkUpRate: 0,
          othercostUnitMarkUpPercentage: 0,
          othercostUnitMarkedUpPrice: 0,
        },
      ],
      othercostPriceSubtotal: 0,
      othercostCostSubtotal: 0,
      othercostProfitMargin: 0,
      othercostProfitMarginPercentage: 0,
      //------------------total data -----------------------
      unitSellPrice: 0,
      unitCostPrice: 0,
      unitProfitMargin: 0,
      unitProfitPercentage: 0,
      lockUnitSellPrice: false,
      unitSellPriceAmount: 0, // unitSellPrice * quantity
      unitCostPriceAmount: 0, // unitCostPrice * quantity
      profitMarginAmount: 0,
      profitMarginPercentage: 0,
      finalFreightRate: 0,
      unitSellPriceWithFreight: 0,
      selectedTax: { type: "GST [1.1]", abbr: "GST" },
      taxTypes: [
        { type: "No TAX", abbr: "NoTAX" },
        { type: "GST [1.1]", abbr: "GST" },
      ],
      unitSellPriceWithTax: 0,
    };
  },
  computed: {
    isSelected() {
      return true;
    },
    showSelected() {
      //console.log(this.selected[0]?.uuid);
      return "test";
    },
    imgInputRules() {
      const rules = [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Image size should be less than 2 MB!",
      ];

      return rules;
    },
    dialogSelectionRules() {
      const rules = [(value) => !!value || "Required."];

      return rules;
    },
  },
  methods: {
    emitSelected() {
      this.$emit("itemDetailObj", this.itemDetailObj);
      this.close();
    },
    close() {
      this.$emit("close-dialog");
    },
    decrementQty() {
      this.quantitySlider = +this.quantitySlider - 20;

      if (this.quantitySlider < this.quantitySliderMin) {
        this.quantitySlider = this.quantitySliderMin;
      }
    },

    incrementQty() {
      this.quantitySlider = +this.quantitySlider + 20;
    },
    setProduct(product) {
      this.product = product;

      this.quantitySlider = product.quantity;
      //this.productCost = product.costPrice;
      //this.productMarkUpRate = product.markUpRate;
      //this.productMarkUpPercentage = product.markUpPercentage;
      //this.productMarkedUpPrice = product.markedUpPrice;
    },
  },
  mounted() {
    this.product = new Product();
  },
};
</script>