import axios  from "axios";
import globalVar from '@/config/main'
import store from "@/store/store";
import router from "@/router";

let config = {
     baseURL: globalVar.API_BASE_URL
};

const httpClient = axios.create(config);

const authIntercepter = config => {
    config.headers['Access-Control-Allow-Origin'] = '*';
    config.headers.Authorization = `Bearer ${store.getters.authUser.access_token}`;    
    return config;
};

httpClient.interceptors.request.use(authIntercepter);

httpClient.interceptors.response.use(
    
    response => {    
       
        //extract header and store in data object
        if(response?.headers['x-pagination-total-count']) {
            response.data.paginationTotalCount = response.headers['x-pagination-total-count'];
        }        
        
        return response;
    },
    error => {
       console.log(error);
        if(error.code === 'ERR_NETWORK') {
            
            router.push({name:'error'});
        }
        if(error.response.status === 401) {
            router.push({name:'login'});
        }

        return Promise.reject(error);
    }
);

export {httpClient};
