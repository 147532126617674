<template>
  <v-app>
    <NavBar />
    <v-main class="grey lighten-4">
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import NavBar from "../../components/NavBar.vue";
export default {
  name: "DefaultLayout",
  components: {
    NavBar,
  },
};
</script>