<template>
  <v-container> <LoginForm /> </v-container>
</template>

<script>
import LoginForm from "../components/LoginForm.vue";
export default {
  name: "DefaultLayout",
  components: {
    LoginForm,
  },
};
</script>