<template>
  <v-container>
    <v-layout row>
      <v-row justify="center" align="center">
        <v-col cols="3">
          <v-row align="center">
            <v-flex xs6 sm6 md5 lg5>
              <span class="caption grey--text">From</span>{{ fromRecord }}-{{
                toRecord
              }}
              <span class="caption ml-2 grey--text">Of</span
              >{{ paginationTotalCount }}
            </v-flex>
            <v-flex xs6 sm6 md5 lg5> Rows per page: </v-flex>
            <v-flex xs6 sm6 md2 lg2>
              <v-select :value="perPage" :items="perPageSelectors" @input="perPageSelect"></v-select>
            </v-flex>
          </v-row>
        </v-col>
        <v-col cols="8">
          <v-container class="max-width">
            <v-pagination
              :value="currentPage"
              class="my-4"
              :length="pageCount"
              @input="nextPage"
            ></v-pagination>
          </v-container>
        </v-col>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  props: {    
    pageCount: {      
      type: Number,
    },
    currentPage: {      
      type: Number,
    },
    perPage: {      
      type: Number,
    },
    paginationTotalCount: {      
      type: Number,
    },
    perPageSelectors: {      
      type: Array,
    },
  },
  emits:['on-change-per-page', 'on-change-page'],   
  computed: {
    fromRecord() {
      return this.perPage * this.currentPage - this.perPage + 1;
    },
    toRecord() {
      const toRecord = this.perPage * this.currentPage;
      return toRecord < this.paginationTotalCount
        ? toRecord
        : this.paginationTotalCount;
    },
  },
  methods: {
    nextPage(p) {
      this.$emit('on-change-page', p);
    },
    perPageSelect(p){
        this.$emit('on-change-per-page', p);
    }
  }
};
</script>
