import VueRouter from "vue-router";
import DefaultLayout from "./views/layouts/DefaultLayout";
import AuthLayout from "./views/layouts/AuthLayout";
import ErrorLayout from "./views/layouts/ErrorLayout";

import GuestView from "./views/GuestView";
//import HomeView from "./views/HomeView"
import DashboardView from "./views/DashboardView";
import QuoteListView from "./views/QuoteListView";
import QuoteView from "./views/QuoteView";
//import GuestView from './views/GuestView'
import store from "@/store/store";

/*
const routes = [
    { path: '', component: HomeView }  ,
    { path: '/login', component: GuestView }  ,
    { path: '/dashboard', component: DashboardView },
    { path: '/quotes', component: QuoteListView },
    { path: '/quotes/:uuid', component: QuoteView }
]
*/

const routes = [
  {
    path: "/",
    name: "default",
    component: DefaultLayout,
    meta: { requiresAuth: true },
    children: [
      {
        path: "error",
        name: "error",
        component: ErrorLayout,
        meta: { requiresAuth: true },
      },
      {
        path: "dashboard",
        name: "dashboard",
        component: DashboardView,
        meta: { requiresAuth: true },
      },
      {
        path: "/quotes",
        name: "quote-list",
        component: QuoteListView,
        meta: { requiresAuth: true },
      },
      {
        path: "/quote/:uid",
        name: "quote-view",
        component: QuoteView,
        props: true,
        meta: { requiresAuth: true },
      },
    ],
  },

  {
    path: "/auth",
    name: "auth",
    component: AuthLayout,
    meta: { guest: true },
    children: [
      {
        path: "login",
        name: "login",
        component: GuestView,
        meta: { guest: true },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  //check for requiresAuth meta routes
  if (
    to.matched.some((record) => {
      console.log(record);
      return record.meta.requiresAuth;
    })
  ) {
    if (store.getters.isAuthenticated && to.name === "default") {
      next({ name: "dashboard" });
      return;
    }

    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    //route is auth require but not authenticated then redirect to login
    next({ name: "login" });
  } else {
    //if not a requiresAuth meta routes but authenticated then redirect to dashboard
    if (store.getters.isAuthenticated && to.name === "error") {
      next({ name: "error" });
    } else if (store.getters.isAuthenticated) {
      next({ name: "dashboard" });
    }
    next();
  }
});

export default router;
