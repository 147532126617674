<template>
  <div class="manage-quote">
    <v-layout row class="mb-5" align-center>
      <h1 class="subheading grey--text">Manage Quotations</h1>
      <v-spacer></v-spacer>
      <v-btn text dark color="light-green accent-4">
        <v-icon color="pink lighten-2">mdi-playlist-plus</v-icon> create new
        quote
      </v-btn>
      <v-btn text dark color="light-green accent-4">
        <v-icon color="pink lighten-2">mdi-autorenew</v-icon> Refresh web quotes
      </v-btn>
    </v-layout>

    <v-container class="my-5">
      <FilterComponent
        :sortables="sortByList"
        :sortParameter="sortParam"
        :searchText="searchText"
        @on-sort-toggle="onSortToggle"
        @on-search="onSearchText"
      />
      <PaginatorComponent
        :currentPage="currentPage"
        :perPage="perPage"
        :paginationTotalCount="paginationTotalCount"
        :perPageSelectors="perPageSelectors"
        @on-change-per-page="onChangePerPage"
        @on-change-page="onChangePage"
      />
      <QuoteItemDetailSkeleton :rows="10" v-if="quotesLoading" />
      <v-container v-if="!quotesLoading">
        <v-card
          flat
          v-for="quote in quotes"
          :key="quote.id"
          class="mb-4 quote-row-card"
        >
          <v-container row :class="`pa-4 quote ${quote.status}`">
            <v-flex xs6 sm6 md2 lg2>
              <div class="caption grey--text">Info</div>
              <div class="font-weight-bold">{{ quote.number }}</div>
              <div>{{ quote.name }}</div>
              <div>
                <span class="subtitle-2 grey--text"
                  >Created @ {{ quote.createdAt }}</span
                >
              </div>
              <div>Due @ {{ quote.dueAt }}</div>
            </v-flex>
            <v-flex xs2 sm4 md2 lg2>
              <div class="caption grey--text">Contact</div>
              <div>
                <span class="subtitle-2 grey--text">Master Customer</span> :
                <span class="subtitle-2"
                  >{{ quote?.customer?.masterCustomer?.name }}
                </span>
              </div>
              <div>
                <span class="subtitle-2 grey--text">Customer</span> :
                <span class="subtitle-2">
                  {{ quote?.customer?.name }}
                </span>
              </div>
              <div>
                <span class="subtitle-2 grey--text">Salesperson</span> :
                <span class="subtitle-2">{{ quote?.salesperson?.name }}</span>
              </div>
              <div>
                <span class="subtitle-2 grey--text">Coordinator</span> :
                <span class="subtitle-2">{{ quote?.coordinator?.name }}</span>
              </div>
            </v-flex>
            <v-flex xs2 sm4 md3 lg3>
              <div class="caption grey--text">Description</div>
              <div v-if="quote?.details">
                <div v-for="(detail, index) in quote.details" :key="index">
                  <span class="caption"
                    >[ {{ detail.name }}:{{ detail.description }} ]</span
                  >&nbsp;<span class="subtitle-2">
                    x {{ detail.quantity }}</span
                  >
                </div>
                <v-chip
                  small
                  label
                  class="ma-2"
                  color="green lighten-2"
                  text-color="white"
                  >{{ quote.details.length }} Products</v-chip
                >
              </div>
            </v-flex>

            <v-flex xs2 sm4 md2 lg2 class="px-6">
              <div class="caption grey--text">Prices</div>
              <div class="font-weight-bold">
                Amount ${{ quote.amountExTax }}
              </div>
              <div>
                <span class="subtitle-2 grey--text"
                  >Estimate ${{ quote.salesEstimate }}
                </span>
              </div>
              <div>
                <span class="subtitle-2 grey--text"
                  >Probability {{ quote.probability }}%</span
                >
              </div>
            </v-flex>
            <v-flex xs2 sm4 md2 lg2>
              <div class="caption grey--text">Status</div>
              <div :class="`${quote.status}--text`">
                {{ quote.status }}
              </div>
              <div>
                <span class="subtitle-2">{{ quote?.crm?.followupStatus }}</span>
              </div>
              <div>
                <span class="subtitle-2 grey--text">Follow-up on</span>
                {{ quote?.crm?.followupOn }}
              </div>
              <div
                :class="{
                  'ACTIVE--text': quote.isActive,
                  'INACTIVE--text': !quote.isActive,
                }"
              >
                {{ quote.isActive ? "ACTIVE" : "INACTIVE" }}
              </div>
            </v-flex>
            <v-flex xs2 sm4 md1 lg1 class="quote-row-option">
              <div class="caption grey--text">Options</div>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="grey"
                    v-bind="attrs"
                    v-on="on"
                    :to="`/quote/${quote.id}`"
                  >
                    <v-icon>mdi-pen</v-icon>
                  </v-btn>
                </template>
                <span>View/Edit</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="grey" v-bind="attrs" v-on="on">
                    <v-icon>mdi-printer</v-icon>
                  </v-btn>
                </template>
                <span>Print PDF</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="grey" v-bind="attrs" v-on="on">
                    <v-icon>mdi-email-outline</v-icon>
                  </v-btn>
                </template>
                <span>Send Quote</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="red lighten-3" v-bind="attrs" v-on="on">
                    <v-icon>mdi-content-duplicate</v-icon>
                  </v-btn>
                </template>
                <span>Clone Quote</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="red lighten-3" v-bind="attrs" v-on="on">
                    <v-icon>mdi-source-branch mdi-rotate-90</v-icon>
                  </v-btn>
                </template>
                <span>Create Order Confirmation</span>
              </v-tooltip>
            </v-flex>
          </v-container>
        </v-card>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import QuoteItemDetailSkeleton from "@/components/skeletons/QuoteItemDetailSkeleton.vue";
import FilterComponent from "@/components/FilterComponent.vue";
import PaginatorComponent from "@/components/PaginatorComponent.vue";
export default {
  components: {
    QuoteItemDetailSkeleton,
    FilterComponent,
    PaginatorComponent,
  },
  data() {
    return {
      //pagination

      currentPage: 1,
      perPage: this.$store.getters.perPage,
      pageCount: 0,
      paginationTotalCount: 0,
      perPageSelectors: [10, 20, 50, 100],

      //sorting andf filtering
      sortByList: [
        {
          id: 1,
          label: "by quote number",
          icon: "mdi-format-list-bulleted",
          dataAttr: "quoteno",
          sort: 1,
          active: false,
        },
        {
          id: 2,
          label: "by created",
          icon: "mdi-calendar",
          dataAttr: "quotecreated",
          sort: -1,
          active: true,
        },
        {
          id: 3,
          label: "by due",
          icon: "mdi-calendar-clock",
          dataAttr: "dueDate",
          sort: 1,
          active: false,
        },
        //{id:4, label:'by customer', icon:'mdi-account-plus', dataAttr:'quoteno', sort: 1, active: false},
        //{id:5, label:'by salesperson', icon:'mdi-tie', dataAttr:'quoteno', sort: 1, active: false},
        //{id:6, label:'by coordinator', icon:'mdi-phone-classic', dataAttr:'quoteno', sort: 1, active: false},
        {
          id: 7,
          label: "by amount",
          icon: "mdi-currency-usd",
          dataAttr: "amount_ex_tax",
          sort: 1,
          active: false,
        },
        {
          id: 8,
          label: "by status",
          icon: "mdi-pulse",
          dataAttr: "quoteno",
          sort: 1,
          active: false,
        },
      ],
      sortParam: "-quotecreated", //default parameter with sorting suffix

      searchText: "",
      //advanced dialog popup
      dialogAdvancedFilter: false,
      quotes: [],
      quotesLoading: false,
    };
  },
  watch: {
    quotes(newQuotes) {
      this.setPageCount(newQuotes);
    },
  },
  methods: {
    onChangePerPage(perPage) {
      this.perPage = perPage;
      this.fetchQuotes();
    },
    onChangePage(page) {
      this.currentPage = page;
      this.fetchQuotes();
    },
    onSortToggle(sortParam) {
      console.log(sortParam);
      this.sortParam = sortParam;
      this.fetchQuotes();
    },
    onSearchText(searchText) {
      this.searchText = searchText;
      this.fetchQuotes();
    },
    setPageCount(newQuotes) {
      this.paginationTotalCount = newQuotes?.paginationTotalCount
        ? newQuotes.paginationTotalCount
        : 0;
      this.pageCount = Math.ceil(
        this.paginationTotalCount / this.perPage
      );
    },
    async fetchQuotes() {
      this.quotesLoading = true;
      //loading the quotes into the component
      const params = {
        expand: "products",
        page: this.currentPage,
        "per-page": this.perPage,
        sort: `${this.sortParam}`,
        "filter[or][][name][like]": this.searchText,
        "filter[or][][number][like]": this.searchText,
        "filter[isActive][eq]": "1",
      };
      await this.$store.dispatch("fetchQuotes", params);
      this.quotes = this.$store.getters.quotes;

      this.quotesLoading = false;
    },
  },
  async mounted() {
    this.fetchQuotes();
  },
};
</script>

<style scoped>
.quote-row-card:hover {
  background-color: #eeeeee;
}

.quote.DRAFT {
  border-left: 4px solid #ffb74d;
}

.quote.APPROVED {
  border-left: 4px solid #76ff03;
}

.quote.ON_APPROVAL {
  border-left: 4px solid #039be5;
}

.quote.REJECTED {
  border-left: 4px solid #f50057;
}

.quote.VOID {
  border-left: 4px solid #ff6d00;
}
/* -------text color according to the status */
.DRAFT--text {
  color: #ffb74d;
}

.APPROVED--text {
  color: #76ff03;
}

.ON_APPROVAL--text {
  color: #039be5;
}

.REJECTED--text {
  color: #f50057;
}

.VOID--text {
  color: #ff6d00;
}

/** -------- active and inactive */
.ACTIVE--text {
  color: #039be5;
  font-weight: bold;
}

.INACTIVE--text {
  color: #f50057;
  font-weight: bold;
}
</style>
