<template>
  <nav>
    <v-toolbar flat app class="grey lighten-4">
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="black--text"
      ></v-app-bar-nav-icon>
      <v-toolbar-title>
        <span class="black--text accent-3">Gun</span>
        <span class="light-green--text accent-3">Web</span>
        <span class="black--text accent-3">Systems</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn depressed @click="logout" color="grey lighten-3">
        <v-icon left color="pink lighten-2">mdi-account-circle</v-icon>
        <span class="pr-2">{{ authUsername }}</span>
        <v-icon right color="pink lighten-2">mdi-logout</v-icon>
      </v-btn>
    </v-toolbar>

    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      app
      class="black accent-3"
    >
      <v-list dense>
        <v-subheader dark>QUICK ACCESS</v-subheader>
        <v-list-item-group v-model="selectedItem" color="light-green">
          <v-list-item v-for="(item, i) in items" :key="i" dark>
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import authService from "../services/auth.service";
export default {
  data() {
    return {
      drawer: false,
      selectedItem: 0,
      items: [
        { text: "Home", icon: "mdi-home-outline", router_name: "default" },
        {
          text: "Dashboard",
          icon: "mdi-view-dashboard",
          router_name: "dashboard",
        },
        { text: "Quotations", icon: "mdi-cart", router_name: "quote-list" },
      ],
    };
  },
  watch: {
    selectedItem(value) {
      this.selectedItem = value;
      const item = this.items[value];
      this.$router.push({ name: item.router_name });
    },
  },
  computed: {
    authUsername() {
      return this.$store.getters.authUser.username;
    },
  },
  methods: {
    logout() {
      authService.logout();
      this.$router.push({ name: "login" });
    },
  },
};
</script>