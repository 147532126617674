import axios from "axios";  
import store from "@/store/store";
import globalVar from '@/config/main'

const authService = {
    authUser:null,
    async login(formData) {
      try {
        const { status, data } = await axios.post(
          globalVar.API_LOGIN_URL, formData
        );
        
        if (status === 200) {
            this.setUser(data);
            
            return {success: true};
        }
       
      } catch (e) {       
        return {
            success: false,
            errors: e.response.data.errors
        }
      }
    },
    setUser(user) {
        this.authUser = user;
    },
    isAuthenticated() {
        return store.getters.isAuthenticated;
    },
    logout() {
        return store.commit('unsetAuthUser');        
    }

};

export default authService;