<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <slot name="activator" v-bind:on="on" v-bind:attrs="attrs"></slot>
      </template>
      <v-card>
        <v-toolbar dark color="light-green accent-4">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Choose a Product</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="emitSelected" :disabled="!isSelected">
              Save
              <v-icon right dark>mdi-content-save</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container class="my-5">
          <v-layout row class="mb-5">
            <template>
              <v-flex xs12 sm12 md12>
                <v-data-table
                  v-model="selected"
                  :headers="headers"
                  :items="items"
                  :search="search"
                  :single-select="true"
                  item-key="uuid"
                  show-select
                  class="elevation-1"
                >
                  <template v-slot:top>
                    <v-card flat>
                      <v-card-title>
                        Products
                        <v-spacer></v-spacer>
                        <v-text-field
                          v-model="search"
                          append-icon="mdi-magnify"
                          label="Search"
                          single-line
                          hide-details
                        ></v-text-field>
                      </v-card-title>
                    </v-card>
                  </template>

                  <!-- header slots -->
                </v-data-table>
              </v-flex>
            </template>
          </v-layout>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  emits: ["emitSelected"],
  data() {
    return {
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      selected: [],
      search: "",
      headers: [
        { text: "Code", value: "code" },
        { text: "Name", value: "name" },
        { text: "Desciption", value: "description" },
        { text: "Supplier", value: "supplier.name" },
      ],
      items: [
        {
          uuid: "d4dedcf2-29be-11ed-a261-0242ac120002",
          code: "6WWS.02225",
          name: "JBs Work Sock 3 Pack - Navy | Orange - King",
          description:
            "DESCRIPTION: Material: Clear PVC COLOUR: Clear SIZE: 85 x 54 x 0.3-0.38mm (thickness) BRANDING: 2 colour print in 1 position plus one colour Scale Crack Guage: UV Print ART: Sika Logo POSITION: As per picture MAX SIZE: As per picture PRINT/THREAD COLOUR: Red & Yellow LEAD TIME: 3-5 weeks from art proof approval (Stock subject to availability)",
          costPrice: 10,
          markedUpPrice: 20,
          imageSrc:
            "https://www.mypromosearch.com.au/vcquotesystem/resources/product_images/RAMO/F393HZ.jpg",
          imageLazySrc: "",
          supplier: {
            uuid: "d4dedcf2-29be-11ed-a261-0242ac120002",
            code: "VISIBLEDIF",
            name: "Headwear Stockists",
          },
          priceMatrix: [
            {
              uuid: "d0e6664e-bc6f-40d4-bf65-107356cb2254",
              priceLevel: "LEVEL_A",
              qtyFrom: 10,
              qtyTo: 49,
              costPrice: 10,
              sellingPrice: 25,
            },
            {
              uuid: "24075b16-318e-11ed-a261-0242ac120002",
              priceLevel: "LEVEL_A",
              qtyFrom: 50,
              qtyTo: 99,
              costPrice: 9.5,
              sellingPrice: 23.5,
            },
            {
              uuid: "346c9bce-318e-11ed-a261-0242ac120002",
              priceLevel: "LEVEL_A",
              qtyFrom: 100,
              qtyTo: 499,
              costPrice: 9,
              sellingPrice: 22,
            },
            {
              uuid: "3a4a195e-318e-11ed-a261-0242ac120002",
              priceLevel: "LEVEL_A",
              qtyFrom: 500,
              qtyTo: 999,
              costPrice: 7.5,
              sellingPrice: 20,
            },
            {
              uuid: "409f3258-318e-11ed-a261-0242ac120002",
              priceLevel: "LEVEL_A",
              qtyFrom: 1000,
              qtyTo: 99999999999,
              costPrice: 6.5,
              sellingPrice: 18,
            },
          ],
        },
        {
          uuid: "4384a8a8-31b2-11ed-a261-0242ac120002",
          code: "114225_TRDZ",
          name: "Chrysler Flat Peak Cap",
          description:
            "Premium structured five panel cap which is manufactured from heavy brushed cotton. It has a trendy flat peak, embroidered eyelets, a sweat band and an adjustable snap back closure that allows one size to fit most people. Colours: Beige, Grey, White, Yellow, Orange, Red, Bright Green, Dark Green, Teal, Light Blue, Royal Blue, Navy, Black. Dimensions: One size fits most people. Branding Options: Screen Print-80mm x 30mm (one colour). Packing: Loose packed. Carton Dimensions: 50cm x 40cm x 40cm x Carton Quantity: 100 pieces Carton Weight: 9.00kg. Price includes : Screen Print",
          costPrice: 5.2,
          sellingPrice: 15,
          image_src:
            "https://www.mypromosearch.com.au/vcquotesystem/resources/product_images/TRENDZ/114225-0.jpg",
          image_lazy_src: "",
          supplier: {
            uuid: "d4dedcf2-29be-11ed-a261-0242ac120002",
            code: "TRENDZ",
            name: "Trends Collection",
          },
          priceMatrix: [
            {
              uuid: "d0e6664e-bc6f-40d4-bf65-107356cb2254",
              priceLevel: "LEVEL_A",
              qtyFrom: 10,
              qtyTo: 49,
              costPrice: 10,
              sellingPrice: 25,
            },
            {
              uuid: "24075b16-318e-11ed-a261-0242ac120002",
              priceLevel: "LEVEL_A",
              qtyFrom: 50,
              qtyTo: 99,
              costPrice: 9.5,
              sellingPrice: 23.5,
            },
            {
              uuid: "346c9bce-318e-11ed-a261-0242ac120002",
              priceLevel: "LEVEL_A",
              qtyFrom: 100,
              qtyTo: 499,
              costPrice: 9,
              sellingPrice: 22,
            },
            {
              uuid: "3a4a195e-318e-11ed-a261-0242ac120002",
              priceLevel: "LEVEL_A",
              qtyFrom: 500,
              qtyTo: 999,
              costPrice: 7.5,
              sellingPrice: 20,
            },
            {
              uuid: "409f3258-318e-11ed-a261-0242ac120002",
              priceLevel: "LEVEL_A",
              qtyFrom: 1000,
              qtyTo: 99999999999,
              costPrice: 6.5,
              sellingPrice: 18,
            },
          ],
        },
        {
          uuid: "66d2acba-31b2-11ed-a261-0242ac120002",
          code: "PF380 _BIZ",
          name: "Oceana Ladies Polo",
          description: "96% Cotton 4% Elastane. WEIGHT: 210 GSM. SIZE: 6 - 24.",
          costPrice: 5.2,
          sellingPrice: 15,
          image_src:
            "http://www.mypromosource.com.au/vcquotesystem/resources/product_images/BIZ/P9025_Worn.jpg",
          image_lazy_src: "",
          supplier: {
            uuid: "d4dedcf2-29be-11ed-a261-0242ac120002",
            code: "BIZ",
            name: "Biz Collection",
          },
          priceMatrix: [
            {
              uuid: "d0e6664e-bc6f-40d4-bf65-107356cb2254",
              priceLevel: "LEVEL_A",
              qtyFrom: 10,
              qtyTo: 49,
              costPrice: 10,
              sellingPrice: 25,
            },
            {
              uuid: "24075b16-318e-11ed-a261-0242ac120002",
              priceLevel: "LEVEL_A",
              qtyFrom: 50,
              qtyTo: 99,
              costPrice: 9.5,
              sellingPrice: 23.5,
            },
            {
              uuid: "346c9bce-318e-11ed-a261-0242ac120002",
              priceLevel: "LEVEL_A",
              qtyFrom: 100,
              qtyTo: 499,
              costPrice: 9,
              sellingPrice: 22,
            },
            {
              uuid: "3a4a195e-318e-11ed-a261-0242ac120002",
              priceLevel: "LEVEL_A",
              qtyFrom: 500,
              qtyTo: 999,
              costPrice: 7.5,
              sellingPrice: 20,
            },
            {
              uuid: "409f3258-318e-11ed-a261-0242ac120002",
              priceLevel: "LEVEL_A",
              qtyFrom: 1000,
              qtyTo: 99999999999,
              costPrice: 6.5,
              sellingPrice: 18,
            },
          ],
        },
      ],
    };
  },
  computed: {
    isSelected() {
      return this.selected.length;
    },
    showSelected() {
      //console.log(this.selected[0]?.uuid);
      return this.selected[0]?.uuid;
    },
  },
  methods: {
    emitSelected() {
      this.$emit("itemSelected", this.selected[0]);
      this.close();
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>