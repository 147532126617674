<template>
  <v-layout row class="mb-2" align-center justify-center>
    <v-card flat id="card-login-form" class="pa-15">
      <v-progress-linear
        :active="authOnProgress"
        :indeterminate="authOnProgress"
        absolute
        top
        color="light-green accent-4"
      ></v-progress-linear>

      <v-card-title class="justify-center display-1">
        <div>
          <span class="black--text accent-3">Gun</span>
          <span class="light-green--text accent-3">Web</span>
          <span class="black--text accent-3">Systems</span>
        </div>
      </v-card-title>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-flex xs12 sm12 md12 lg12>
          <v-text-field
            v-model="username"
            :rules="usernameRules"
            label="Username"
            required
            color="light-green accent-4"
          >
            <template v-slot:prepend>
              <v-icon color="light-green accent-3"> mdi-account </v-icon>
            </template>
          </v-text-field>
        </v-flex>
        <v-flex xs12 sm12 md12 lg12>
          <v-text-field
            v-model="password"
            :rules="passwordRules"
            label="Passwrod"
            type="password"
            required
            color="light-green accent-4"
          >
            <template v-slot:prepend>
              <v-icon color="light-green accent-3"> mdi-lock </v-icon>
            </template>
          </v-text-field>
        </v-flex>
        <v-flex xs12 sm12 md12 lg12 class="mt-10">
          <v-row class="align-center justify-center">
            <v-btn
              class="ma-2"
              :loading="authOnProgress"
              :disabled="authOnProgress"
              color="light-green accent-4 white--text"
              width="200"
              depressed
              @click="login"
            >
              {{ loginButtonText }}
              <v-icon small v-if="loginButtonText === 'login'"
                >mdi-arrow-right</v-icon
              >
              <template v-slot:authOnProgress>
                <span>Authenticating...</span>
              </template>
            </v-btn>
          </v-row>
        </v-flex>
      </v-form>
      <v-card-subtitle
        v-if="authErrors"
        class="justify-center"
        style="height: 50px"
      >
        <div style="color: #d50000; font-size: 0.8rem; text-align: center">
          <p v-for="(error, field) in authErrors" :key="field">
            {{ error[0] }}
          </p>
        </div>
      </v-card-subtitle>
    </v-card>
  </v-layout>
</template>
<script>
import authService from "../services/auth.service";

export default {
  data: () => ({
    loginButtonText: "login",
    authOnProgress: false,
    authErrors: null,
    username: "",
    usernameRules: [(v) => !!v || "Username is required"],
    password: "",
    passwordRules: [(v) => !!v || "password is required"],
  }),

  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async login() {
      if (this.validate()) {
        this.authOnProgress = true;

        const { success, errors } = await authService.login({
          username: this.username,
          password: this.password,
        });

        if (success) {
          this.$store.dispatch("setAuthUser", authService.authUser);
          this.authErrors = null;
          this.loginButtonText = "authentictaed";
          this.$router.push({ name: "dashboard" });
        } else {
          this.authErrors = errors;
        }

        this.authOnProgress = false;
      }
    },
  },
};
</script>
<style scoped>
#card-login-form {
  width: 30vw;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -75%);
}
</style>