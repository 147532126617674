<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <slot name="activator" v-bind:on="on" v-bind:attrs="attrs"></slot>
      </template>
      <v-card>
        <v-toolbar dark color="light-green accent-4">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Choose Salesperson</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="emitSelected" :disabled="!isSelected">
              Save
              <v-icon right dark>mdi-content-save</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container class="my-5">
          <v-layout row class="mb-5">
            <template>
              <v-flex xs12 sm12 md12>
                <v-data-table
                  v-model="selected"
                  :headers="headers"
                  :items="items"
                  :search="search"
                  :single-select="true"
                  item-key="uuid"
                  show-select
                  class="elevation-1"
                >
                  <template v-slot:top>
                    <v-card flat>
                      <v-card-title>
                        Salespersons
                        <v-spacer></v-spacer>
                        <v-text-field
                          v-model="search"
                          append-icon="mdi-magnify"
                          label="Search"
                          single-line
                          hide-details
                        ></v-text-field>
                      </v-card-title>
                    </v-card>
                  </template>

                  <!-- header slots -->
                  <template v-slot:[`header.code`]="{ header }">
                    <v-icon left>mdi-tag-text-outline</v-icon>
                    {{ header.text.toUpperCase() }}
                  </template>
                  <template v-slot:[`header.name`]="{ header }">
                    <v-icon left>mdi-account</v-icon>
                    {{ header.text.toUpperCase() }}
                  </template>
                  <template v-slot:[`header.email`]="{ header }">
                    <v-icon left>mdi-email</v-icon>
                    {{ header.text.toUpperCase() }}
                  </template>
                  <template v-slot:[`header.phone`]="{ header }">
                    <v-icon left>mdi-phone</v-icon>
                    {{ header.text.toUpperCase() }}
                  </template>
                  <template v-slot:[`header.username`]="{ header }">
                    <v-icon left>mdi-account-key</v-icon>
                    {{ header.text.toUpperCase() }}
                  </template>
                  <template v-slot:[`header.status`]="{ header }">
                    <v-icon left>mdi-pulse</v-icon>
                    {{ header.text.toUpperCase() }}
                  </template>

                  <!-- table row slots -->
                  <template v-slot:[`item.username`]="{ item }">
                    {{ item.username }} - {{ item.user_role }}
                  </template>
                  <template v-slot:[`item.status`]="{ item }">
                    <v-chip
                      v-if="getStatusClass(item.status)"
                      x-small
                      :class="getStatusClass(item.status)"
                      dark
                    >
                      {{ item.status }}
                    </v-chip>
                  </template>
                </v-data-table>
              </v-flex>
            </template>
          </v-layout>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  emits: ["emitSelected"],
  data() {
    return {
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      selected: [],
      search: "",
      headers: [
        {
          text: "Code",
          align: "start",
          value: "code",
        },
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Telephone", value: "phone" },
        { text: "Username", value: "username" },
        { text: "Status", value: "status" },
      ],
      items: [
        {
          id:1,
          uuid: "d4dedcf2-29be-11ed-a261-0242ac120002",
          code: "LKG",
          name: "Chanelle Bond",
          email: "Chanelle@thepromolab.co.nz",
          phone: "0403495818",
          username: "Admin",
          user_role: "Sales Admin",
          status: "ACTIVE",
        },
        {
          id:1,
          uuid: "dfd7b840-29be-11ed-a261-0242ac120002",
          code: "KI",
          name: "Katie Inwood",
          email: "katie@thepromolab.co.nz",
          phone: "0403495818",
          username: "Admin",
          user_role: "Sales Admin",
          status: "INACTIVE",
        },
        {
          id:1,
          uuid: "f0e3cbc4-29be-11ed-a261-0242ac120002",
          code: "LK",
          name: "Lee King",
          email: "Chanelle@thepromolab.co.nz",
          phone: "0403495818",
          username: "Admin",
          user_role: "Sales Admin",
          status: "ACTIVE",
        },
        {
          id:1,
          uuid: "f883a336-29be-11ed-a261-0242ac120002",
          code: "LKG",
          name: "Chanelle Bond",
          email: "Chanelle@thepromolab.co.nz",
          phone: "0403495818",
          username: "Admin",
          user_role: "Sales Admin",
          status: "ACTIVE",
        },
        {
          id:1,
          uuid: "ffb3255a-29be-11ed-a261-0242ac120002",
          code: "LKG",
          name: "Chanelle Bond",
          email: "Chanelle@thepromolab.co.nz",
          phone: "0403495818",
          username: "Admin",
          user_role: "Sales Admin",
          status: "ACTIVE",
        },
        {
          id:1,
          uuid: "081023f6-29bf-11ed-a261-0242ac120002",
          code: "LKG",
          name: "Chanelle Bond",
          email: "Chanelle@thepromolab.co.nz",
          phone: "0403495818",
          username: "Admin",
          user_role: "Sales Admin",
          status: "ACTIVE",
        },
        {
          id:1,
          uuid: "10f25412-29bf-11ed-a261-0242ac120002",
          code: "LKG",
          name: "Chanelle Bond",
          email: "Chanelle@thepromolab.co.nz",
          phone: "0403495818",
          username: "Admin",
          user_role: "Sales Admin",
          status: "ACTIVE",
        },
        {
          id:1,
          uuid: "192850a0-29bf-11ed-a261-0242ac120002",
          code: "LKG",
          name: "Chanelle Bond",
          email: "Chanelle@thepromolab.co.nz",
          phone: "0403495818",
          username: "Admin",
          user_role: "Sales Admin",
          status: "ACTIVE",
        },
        {
          id:1,
          uuid: "219afac6-29bf-11ed-a261-0242ac120002",
          code: "LKG",
          name: "Chanelle Bond",
          email: "Chanelle@thepromolab.co.nz",
          phone: "0403495818",
          username: "Admin",
          user_role: "Sales Admin",
          status: "ACTIVE",
        },
        {
          id:1,
          uuid: "2a938116-29bf-11ed-a261-0242ac120002",
          code: "LKG",
          name: "Chanelle Bond",
          email: "Chanelle@thepromolab.co.nz",
          phone: "0403495818",
          username: "Admin",
          user_role: "Sales Admin",
          status: "ACTIVE",
        },
      ],
    };
  },
  computed: {
    isSelected() {
      return this.selected.length;
    },
    showSelected() {
      //console.log(this.selected[0]?.uuid);
      return this.selected[0]?.uuid;
    },
  },
  methods: {
    emitSelected() {
      this.$emit("itemSelected", this.selected[0]);
      this.close();
    },
    close() {
      this.dialog = false;
    },
    getStatusClass(value) {
      if (!value) {
        return "";
      }

      if (value === "ACTIVE") {
        return "green lighten-1";
      }

      if (value === "INACTIVE") {
        return "red lighten-1";
      }
    },
  },
};
</script>