<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <slot name="activator" v-bind:on="on" v-bind:attrs="attrs"></slot>
      </template>
      <v-card class="grey lighten-4">
        <v-toolbar dark color="light-green accent-4">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Choose Customer Contact</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="emitSelected" :disabled="!isSelected">
              Save
              <v-icon right dark>mdi-content-save</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container class="my-5">
          <v-layout row class="mb-5">
            <template>
              <v-flex xs12 sm12 md12>
                <v-data-table
                  v-model="selected"
                  :headers="headers"
                  :items="items"
                  :search="search"
                  :single-select="true"
                  item-key="uuid"
                  show-select
                  class="elevation-1"

                  disable-sort="true"
                  hide-default-footer="true"
                >
                  <template v-slot:top>
                    <v-card flat class="ma-5">
                      <v-card-title> Customer Contacts </v-card-title>
                      <v-card-text class="ma-2">
                        <v-layout row class="mb-5" align-center justify-center>
                          <span class="caption mr-2 grey--text">Sort By</span>
                          <v-btn
                            :class="{ 'pink--text': sortBy.active }"
                            small
                            depressed
                            @click="toggleSortBy(sortBy.id)"
                            v-for="sortBy in sortByList"
                            :key="sortBy.id"
                          >
                            <v-icon left small>{{ sortBy.icon }}</v-icon>
                            <span class="caption text-lowercase">{{
                              sortBy.label
                            }}</span>
                            <v-icon
                              v-if="sortBy.active"
                              right
                              x-small
                              color="pink darken-1"
                              >mdi-call-received
                              {{
                                sortBy.sort > 0
                                  ? "mdi-rotate-315"
                                  : "mdi-rotate-135"
                              }}</v-icon
                            >
                          </v-btn>

                          <v-spacer></v-spacer>
                          <v-text-field
                            v-model="searchText"
                            label="Search by customer contact related fields"
                            single-line
                            @keydown.enter="searchForText"
                            clearable
                          ></v-text-field>
                          <v-btn depressed @click="searchForText">
                            <v-icon>mdi-magnify</v-icon>
                          </v-btn>
                        </v-layout>
                        <v-layout row>
        <v-row justify="center" align="center">
          <v-col cols="3">
            <v-row align="center">
              <v-flex xs6 sm6 md5 lg5>
                <span class="caption grey--text">From</span>{{ fromRecord }}-{{
                  toRecord
                }}
                <span class="caption ml-2 grey--text">Of</span
                >{{ paginationTotalCount }}
              </v-flex>
              <v-flex xs6 sm6 md5 lg5> Rows per page: </v-flex>
              <v-flex xs6 sm6 md2 lg2>
                <v-select
                  v-model="perPage"
                  :items="perPageSelectors"
                ></v-select>
              </v-flex>
            </v-row>
          </v-col>
          <v-col cols="8">
            <v-container class="max-width">
              <v-pagination
                v-model="currentPage"
                class="my-4"
                :length="pageCount"
                @input="nextPage"
              ></v-pagination>
            </v-container>
          </v-col>
        </v-row>
      </v-layout>
                      </v-card-text>
                    </v-card>
                  </template>

                  <!-- header slots -->
                  <template v-slot:[`header.company`]="{ header }">
                    <v-icon left>mdi-account</v-icon>
                    {{ header.text.toUpperCase() }}
                  </template>
                  <template v-slot:[`header.address`]="{ header }">
                    <v-icon left>mdi-map-marker</v-icon>
                    {{ header.text.toUpperCase() }}
                  </template>
                  <template v-slot:[`header.email`]="{ header }">
                    <v-icon left>mdi-email</v-icon>
                    {{ header.text.toUpperCase() }}
                  </template>
                  <template v-slot:[`header.phone`]="{ header }">
                    <v-icon left>mdi-phone</v-icon>
                    {{ header.text.toUpperCase() }}
                  </template>
                  <template v-slot:[`header.contact`]="{ header }">
                    <v-icon left>mdi-account-network</v-icon>
                    {{ header.text.toUpperCase() }}
                  </template>
                  <template v-slot:[`header.salesperson`]="{ header }">
                    <v-icon left>mdi-tie</v-icon>
                    {{ header.text.toUpperCase() }}
                  </template>
                  <template v-slot:[`header.account_status`]="{ header }">
                    <v-icon left>mdi-pulse</v-icon>
                    {{ header.text.toUpperCase() }}
                  </template>

                  <!-- table row slots -->
                  <template v-slot:[`item.company`]="{ item }">
                    {{ item.company }}
                    <v-chip
                      v-if="getApiClass(item.api)"
                      x-small
                      :class="getApiClass(item.api)"
                      dark
                    >
                      <v-icon small left>mdi-cloud-sync</v-icon> {{ item.api }}
                    </v-chip>
                  </template>
                  <template v-slot:[`item.account_status`]="{ item }">
                    <v-chip
                      v-if="getStatusClass(item.account_status)"
                      x-small
                      :class="getStatusClass(item.account_status)"
                      dark
                    >
                      {{ item.account_status }}
                    </v-chip>
                  </template>
                </v-data-table>
              </v-flex>
            </template>
          </v-layout>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  emits: ["emitSelected"],
  data() {
    return {
      dialog: false,
      selected: [],
      //sorting andf filtering
      sortByList: [
        {
          id: 1,
          label: "by company",
          icon: "mdi-account",
          dataAttr: "cusnam",
          sort: 1,
          active: true,
        },
        {
          id: 2,
          label: "by master customer",
          icon: "mdi-account-plus",
          dataAttr: "cusmcussno",
          sort: -1,
          active: false,
        },
        {
          id: 3,
          label: "by address",
          icon: "mdi-map-marker",
          dataAttr: "cusadd1",
          sort: 1,
          active: false,
        },
        //{id:4, label:'by customer', icon:'mdi-account-plus', dataAttr:'quoteno', sort: 1, active: false},
        //{id:5, label:'by salesperson', icon:'mdi-tie', dataAttr:'quoteno', sort: 1, active: false},
        //{id:6, label:'by coordinator', icon:'mdi-phone-classic', dataAttr:'quoteno', sort: 1, active: false},
        {
          id: 7,
          label: "by email",
          icon: "mdi-email",
          dataAttr: "cusemail",
          sort: 1,
          active: false,
        },
        {
          id: 8,
          label: "by telephone",
          icon: "mdi-phone",
          dataAttr: "custel",
          sort: 1,
          active: false,
        },
        {
          id: 9,
          label: "by contact",
          icon: "mdi-account-network",
          dataAttr: "cuscontact",
          sort: 1,
          active: false,
        },
        {
          id: 10,
          label: "by salesperson",
          icon: "mdi-tie",
          dataAttr: "cussalesperson",
          sort: 1,
          active: false,
        },
        {
          id: 11,
          label: "by status",
          icon: "mdi-pulse",
          dataAttr: "account_inactive",
          sort: 1,
          active: false,
        },
      ],
      sortParam: "cusnam", //default parameter with sorting suffix

      searchText: "",

      /**--------------DATA TABLE ---------------- */
      headers: [
        {
          text: "Company",
          align: "start",
          value: "company",
          width: "14.2%",
        },
        { text: "Master Customer", value: "master", width: "12.2%" },
        {
          text: "Address",
          value: "address",
          width: "14.2%",
        },
        { text: "Email", value: "email", width: "14.2%" },
        { text: "Telephone", value: "phone", width: "10%" },
        { text: "Contact", value: "contact", width: "10%" },
        { text: "Salesperson", value: "salesperson", width: "10%" },
        { text: "Status", value: "account_status", width: "10%" },
      ],
      items: [
        {
          id: 1,
          uuid: "d4dedcf2-29be-11ed-a261-0242ac120002",
          master: "100 The Street",
          company: "121 Creative",
          name: "121 Creative",
          address: "173 Allinga Road Woongarrah",
          email: "irene018@bigpond.net.au",
          phone: "0403495818",
          contact: "Irene Ballard",
          salesperson: "kaite",
          account_status: "ACTIVE",
          api: "XERO",
        },
        {
          id: 1,
          uuid: "dfd7b840-29be-11ed-a261-0242ac120002",
          master: "Accent Tools Ltd",
          company: "5th Wheeler",
          name: "5th Wheeler",
          address: "UNIT2 9 TIMMS RD",
          email: "dummy@test.com",
          phone: "3353 9707",
          contact: "IALAN",
          salesperson: "kaite",
          account_status: "ACTIVE",
          api: "",
        },
        {
          id: 1,
          uuid: "f0e3cbc4-29be-11ed-a261-0242ac120002",
          master: "Harveys Manurewa",
          company: "AA Fencing Enterprises",
          name: "5th Wheeler",
          address: "19A Bassendean Rd, Bayswater",
          email: "jeff@infomazeapps.com",
          phone: "3353 9706",
          contact: "Jeff",
          salesperson: "kaite",
          account_status: "INACTIVE",
          api: "",
        },
        {
          id: 1,
          uuid: "f883a336-29be-11ed-a261-0242ac120002",
          master: "Gun Web Systems",
          company: "Big River Toyota",
          name: "Big River Toyota",
          address: "PO Box 1333",
          email: "kayla.marschall@bigrivertoyota.com.au",
          phone: "08 8582 2277",
          contact: "BRUCE",
          salesperson: "Bill McDonald",
          account_status: "ACTIVE",
          api: "XERO",
        },
        {
          id: 1,
          uuid: "ffb3255a-29be-11ed-a261-0242ac120002",
          master: "Jennian Homes Southland",
          company: "Corrimal RSL Club",
          name: "Corrimal RSL Club",
          address: "168 Princes Highway",
          email: "dakoda11@mail.com",
          phone: "0423660528",
          contact: "Oliver Insurance",
          salesperson: "Bill McDonald",
          account_status: "ACTIVE",
          api: "MYOB",
        },
        {
          id: 1,
          uuid: "081023f6-29bf-11ed-a261-0242ac120002",
          master: "Mercedes-Benz New Zealand",
          company: "Department of Defence",
          name: "Department of Defence",
          address: "PO Box 7927, Canberra BC",
          email: "denzil.don.free.kin@kindergarten.vic.gov.au",
          phone: "9380 8420",
          contact: "Demo Printers1",
          salesperson: "Bill McDonald",
          account_status: "INACTIVE",
          api: "",
        },
        {
          id: 1,
          uuid: "10f25412-29bf-11ed-a261-0242ac120002",
          master: "Ray White Universal",
          company: "Global Learning Support",
          name: "Global Learning Support",
          address: "Level 1, 636 St Kilda Road",
          email: "jphoon@globallearningsupport.com",
          phone: "0491 211 277",
          contact: "Marc",
          salesperson: "Bill McDonald",
          account_status: "INACTIVE",
          api: "",
        },
        {
          id: 1,
          uuid: "192850a0-29bf-11ed-a261-0242ac120002",
          master: "ReMax Rolleston",
          company: "Headspace Armadale",
          name: "Headspace Armadale",
          address:
            "Bentleigh Armadale Medicare Local, Unit 4, 1140 Albany Highway, Annette Chivers - Mental Health Manager",
          email: "i.mahmoud@archehealth.com.au",
          phone: "(08) 9458 0505 AP",
          contact: "Gemma McConnell",
          salesperson: "Bill McDonald",
          account_status: "ACTIVE",
          api: "XERO",
        },
        {
          id: 1,
          uuid: "219afac6-29bf-11ed-a261-0242ac120002",
          master: "100 The Street",
          company: "Monash University Accident Research Centre",
          name: "Monash University Accident Research Centre",
          address: "Level 3, 21 Alliance Lane, Monash University",
          email: "megan.keating@med.monash.edu.au",
          phone: "9905 1853",
          contact: "Mrs A Curtis",
          salesperson: "Bill McDonald",
          account_status: "ACTIVE",
          api: "",
        },
        {
          id: 1,
          uuid: "2a938116-29bf-11ed-a261-0242ac120002",
          master: "100 The Street",
          company: "SAE Australasia",
          name: "SAE Australasia",
          address: "Unit 30, 3 Westside Avenue",
          email: "finance@sae-a.com.au",
          phone: "03 9676 9568",
          contact: "RAJESH KUMAR",
          salesperson: "Bill McDonald",
          account_status: "ACTIVE",
          api: "MYOB",
        },
      ],
    };
  },
  computed: {
    isSelected() {
      return this.selected.length;
    },
    showSelected() {
      //console.log(this.selected[0]?.uuid);
      return this.selected[0]?.uuid;
    },
  },
  methods: {
    emitSelected() {
      this.$emit("itemSelected", this.selected[0]);
      this.close();
    },
    close() {
      this.dialog = false;
    },
    getApiClass(value) {
      if (!value) {
        return "";
      }

      if (value === "XERO") {
        return "blue lighten-1";
      }

      if (value === "MYOB") {
        return "purple lighten-1";
      }
    },

    getStatusClass(value) {
      if (!value) {
        return "";
      }

      if (value === "ACTIVE") {
        return "green lighten-1";
      }

      if (value === "INACTIVE") {
        return "red lighten-1";
      }
    },
  },
};
</script>
